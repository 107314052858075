import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDateTime, paymentVerboseFetch } from "components/Common/common"
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import toastr from "toastr"
import { ReportDownload } from "components/Common/print-report"

const MedicineSaleReports = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [medicineSaleObj, setMedicineSaleObj] = useState({})
  const [medicineSaleList, setMedicineSaleList] = useState({})
  const [modals, setModals] = useState({ deleteModal: false })

  const { loading, error, options, hmsList, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/medicine_sales/?options=table, search, daterange, filters", "medicine_sale_reports"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (apiKey === "medicine_sale_reports_list") setMedicineSaleList(hmsList)
    if (apiKey === "medicine_sale_reports") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/pharmacy/medicine_sales/", decodeURIComponent(urlParams).replace("?", ""), "medicine_sale_reports_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.sold_out_medicines
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/pharmacy/medicine_sales/", savedFilters, "medicine_sale_reports_list"))
      }
    }
    if (apiKey === "medicine_sale_report_deleted") {
      setMedicineSaleObj(hmsList)
      toggleDelete()
      toastr.success("Sold out medicine sale is deleted")
    }
  }, [apiKey])

  useEffect(() => {
    if (!isEmpty(options) && apiKey === "medicine_sale_reports") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "uid") {
            return {
              ...item,
              formatter: (cellContent, row) => <Link to={`/hms/pharmacy/sold-out-medicines/${row.id}`}>{row.billing.uid}</Link>
            }
          } else if (item.dataField === "patient_name") {
            return {
              ...item,
              formatter: (cellContent, row) => row.billing.customer_name
            }
          } else if (item.dataField === "patient_uid") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.patient_uid || "---"
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.patient?.phone_number || "---"
            }
          } else if (item.dataField === "module") {
            return {
              ...item,
              formatter: (cellContent, row) => row.billing.module
            }
          } else if (item.dataField === "amount") {
            return {
              ...item,
              formatter: (cellContent, row) => row.billing.grand_total_amount
            }
          } else if (item.dataField === "payment_status") {
            return {
              ...item,
              formatter: (cellContent, row) => row.billing.is_paid ? <Badge pill color="success">Paid</Badge> : <Badge pill color="danger">Unpaid</Badge>
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(row.created_at) || "---"
            }
          } else if (item.dataField === "payment_mode") {
            return {
              ...item,
              formatter: (cellContent, row) => paymentVerboseFetch(row.billing.payment_mode) || "---"
            }
          } else if (item.dataField === "created_by") {
            return {
              ...item,
              formatter: (cellContent, row) => row.created_by_name || "---"
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16" color="white">
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <ReportDownload
                  url={`/hms/pharmacy/medicine_sales/${row?.id}/download_report/`}
                  label="Print Receipt"
                  type="menu-item"
                />
                <Link className="dropdown-item" to={`/hms/pharmacy/medicine-sale/${row.id}`}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/pharmacy/medicine_sales/", urlParams, "medicine_sale_reports_list"))
      setMedicineSaleList({})
    } else {
      dispatch(listHms("/hms/pharmacy/medicine_sales/", null, "medicine_sale_reports_list"))
      setMedicineSaleList({})
    }
  }

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = (medicine_sale_object) => {
    setMedicineSaleObj(medicine_sale_object)
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sold Out Medicines | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_medicine_sale_reports" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="sold_out_medicines"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField="id"
              noDataIndication="No sales happen"
              data={medicineSaleList}
              loading={apiKey === "list_medicine_sale_reports_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
            />
          )}
        </Container>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If sold out medicine is removed, you cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/pharmacy/medicine_sales/", medicineSaleObj?.id, "medicine_sale_report_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment>
  )
}

export default MedicineSaleReports
