import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms, chainedOptionsHms, getHmsOptions } from "store/actions"
import { EAvFieldInput, EAvFieldSelect, EAvFieldNumber, RequiredFieldsMessage, EAsyncSelect, EAvFieldGenericInput } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { EAvFieldDOB, GoBack, errorMappings, handlePhonenumberBlur, handlePhonenumberFocus, Loader, SubmitLoaderButton, formSchemaConversionAvField } from "pages/HMS/common/common"
import Select from 'react-select';
import { isEmpty } from "lodash"
import moment from "moment";
import { get } from "helpers/api_helper";
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import ConfirmationDialog from "pages/HMS/common/confirmation-dialog"
import DigitalSignature from "pages/HMS/common/digital-signature"
import toastr from "toastr"

export const DoctorCRUForm = ({ options, historyProp, apiStartKey, isChecked, setIsChecked, state, setState }) => {
  const dispatch = useDispatch()
  const formRef = useRef(null);
  const { hmsObject, formError, apiKey, postResponse, optionsChainedHms, modifiedFormSchema } = useSelector((state) => state.Hms)
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectError, setSelectError] = useState(false);
  const [phonenumber, setPhonenumber] = useState(null);
  const [doctor, setDoctor] = useState({})
  const [searchOptions, setSearchOptions] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState(null);
  const [modalQualification, setModalQualification] = useState(false)
  const [modalSignature, setModalSignature] = useState(false)
  const [phoneError, setPhoneError] = useState('');
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [roleDefaultOptions, setRoleDefaultOptions] = useState([])
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [signature, setSignature] = useState(null);
  const [age, setAge] = useState({ dob: null, years: null, months: null, days: null })

  const validateTimeSlots = () => {
    if (timeSlots === null || timeSlots.length === 0) {
      setSelectError(true);
      return false;
    }
    setSelectError(false);
    return true;
  };

  useEffect(() => {
    if (apiKey === "get_doctor") {
      setDoctor(hmsObject)
      if (hmsObject?.user_data?.dob) {
        setAge({
          dob: hmsObject.user_data.dob,
          years: hmsObject.user_data.age,
          months: moment().diff(hmsObject.user_data.dob, "months"),
          days: moment().diff(hmsObject.user_data.dob, "days"),
        })
      }
      setTimeSlots(hmsObject.time_slots)
      setSelectedQualifications(hmsObject.qualifications)
      setSelectedRoles(hmsObject.user_data.groups)
      handleSaveSignature(hmsObject.signature)
    }
    if (apiKey === "doctor_qualification_created") {
      setSelectedQualifications(selectedQualifications === null ? [postResponse] : [...selectedQualifications, postResponse]);
    }
    if (apiKey === "fetch_roles") {
      setRoleDefaultOptions(optionsChainedHms?.results);
    }
    if (apiKey === "fetch_qualifications") {
      setSearchOptions(optionsChainedHms);
    }
    if (formError?.detail && apiKey.startsWith("fail_doctor_")) {
      setActiveOPAlert(true)
      const [code, username, full_name] = formError?.detail.split("_-_");
      setState((prevState) => ({ ...prevState, ...errorMappings[code], username, full_name }));
      setSelectedPerson({ username, full_name })
    }
  }, [apiKey])

  const updateQualificationSelect = (qualifications) => {
    setSelectedQualifications(qualifications)
  }

  var suTo = null
  const searchRoles = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/roles/?search=${value}`).then((resp) => {
          callback(resp?.results)
        })
      }, 1000)
    }
  }

  const searchQualifications = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/qualifications/fetch_all/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 1000)
    }
  }

  const searchDiscountUsers = (value, callback) => {
    value = value.trim()
    setIsMenuOpen(value.length > 0)
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/outpatients/fetch_discount_users/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  function toggleQualification(def = modalQualification) {
    setModalQualification(!def)
  }

  const handleSubmit = (event, values) => {
    if (!phonenumber && !isChecked) {
      setPhoneError('This field is invalid');
      return;
    }
    setPhoneError('');
    const isValid = validateTimeSlots();
    if (isValid) {
      values.qualifications = !isEmpty(selectedQualifications) ? selectedQualifications?.map(qualification => qualification?.id) : null;
      values.signature = signature
      values.user_post = {
        full_name: values.full_name,
        username: values.username,
        email: values.email,
        phone_number: phonenumber,
        gender: values.gender,
        dob: age?.dob,
        groups: !isEmpty(selectedRoles) ? selectedRoles?.map(role => role?.id) : [],
        address: values.address
      }
      delete values.full_name
      delete values.username
      delete values.email
      delete values.phone_number
      delete values.gender
      delete values.dob
      delete values.address
      values.specializations = [values.specializations]
      values.time_slots = timeSlots
      let doctorID = doctor.id
      if (state.isExist || isChecked) {
        delete values.user_post
        values.username = state?.username || selectedPerson?.username
      }

      if (doctorID) {
        dispatch(updateHms("/hms/staff/doctors/", doctorID, values, `${apiStartKey}_updated`))
      } else {
        dispatch(postHms('/hms/staff/doctors/', values, `${apiStartKey}_created`))
      }
    }
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }));
    setIsChecked(true);
    setActiveOPAlert(false)
    formRef.current.submit();
  }

  const toggleSignature = (def = modalSignature) => {
    setModalSignature(!def);
  }

  const handleSaveSignature = (signatureData) => {
    setSignature(signatureData);
  };

  return (
    <div>
      <AvForm className="form-horizontal" onValidSubmit={handleSubmit} ref={formRef}>
        {state.isExist || isChecked ? (
          <Row>
            <Col>
              <div className="mb-3 ajax-select select2-container">
                <EAsyncSelect
                  isDisabled={!isChecked}
                  options={{ ...options?.form_schema?.full_name, label: "Doctor name" }}
                  getOptionLabel={e => e?.full_name}
                  getOptionValue={e => e?.username}
                  placeholder="Search users..."
                  onMenuClose={() => setIsMenuOpen(false)}
                  menuIsOpen={isMenuOpen}
                  selectedOption={[selectedPerson]}
                  fetchOptions={searchDiscountUsers}
                  onSelect={(value) => setSelectedPerson(value)}
                  formError={formError?.username}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <EAvFieldInput
                  field="full_name"
                  type="text"
                  value={doctor?.user_data?.full_name}
                  isError={formError?.full_name}
                  options={{ ...options?.form_schema?.full_name, label: "Doctor name" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EAvFieldInput
                  field="username"
                  type="text"
                  value={doctor?.user_data?.username}
                  isError={formError?.username}
                  options={{ ...options?.form_schema?.username, label: "Username", required: true }}
                  placeholder={"Ex: user-123"}
                  helpMessage={"An unique id to identify the user"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <PhonenumberInput
                    label="Mobile"
                    name="phone_number"
                    value={doctor?.user_data?.phone_number}
                    error={formError?.phone_number || phoneError}
                    required={true}
                    updateValue={setPhonenumber}
                    onFocus={() => handlePhonenumberFocus(setPhoneError)}
                    onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                  />
                </div>
              </Col>
              <Col>
                <EAvFieldInput
                  field="email"
                  type="email"
                  value={doctor?.user_data?.email}
                  isError={formError?.email}
                  options={{ ...options?.form_schema?.email, label: "Email", max_length: 50, required: true }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EAvFieldSelect
                  field="gender"
                  value={doctor?.user_data ? doctor?.user_data?.gender : "MALE"}
                  isError={formError?.gender}
                  options={{ label: "Gender", required: true }}
                  choices={<>
                    <option>-------Select an option-------</option>
                    {(options.form_schema && options.form_schema.gender) && options.form_schema.gender.choices.map((choice, cidx) =>
                      <option value={choice.value} key={cidx}>{choice.display_name}</option>
                    )}
                  </>}
                />
              </Col>
              <Col>
                <EAvFieldDOB
                  age={age}
                  setAge={setAge}
                  required={options?.form_schema?.dob?.required}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                  <EAsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions={roleDefaultOptions}
                    onFocus={() => dispatch(chainedOptionsHms("/hms/roles/", null, "fetch_roles"))}
                    options={options?.form_schema?.groups}
                    selectedOption={selectedRoles}
                    fetchOptions={searchRoles}
                    formError={formError?.groups}
                    onSelect={(role) => setSelectedRoles(role)}
                    placeholder="Type to search roles..."
                    getOptionLabel={e => e?.name}
                    getOptionValue={e => e?.id}
                    helpMessage={options?.form_schema?.groups?.help_text}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <h5 className="mt-2">Professional Information</h5>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <EAvFieldSelect
              field="specializations"
              value={doctor?.specializations && doctor?.specializations[0]}
              isError={formError?.specializations}
              options={options?.form_schema?.specializations}
              choices={<>
                <option>-------Select an option-------</option>
                {options?.form_schema?.specializations?.choices.map((choice, cidx) =>
                  <option value={choice.id} key={cidx}>{choice.name}</option>
                )}
              </>}
            />
          </Col>
          <Col md={6} sm={12}>
            <EAvFieldNumber
              field="fee"
              value={doctor?.fee}
              isError={formError?.fee}
              options={options?.form_schema?.fee}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className={selectError ? "text-danger" : ""}>Schedules</Label>
              <Select
                closeMenuOnSelect={false}
                value={timeSlots}
                isMulti
                options={options?.form_schema?.time_slots?.choices}
                getOptionLabel={e => moment(e, 'HH:mm:ss').format('hh:mm A')}
                getOptionValue={e => e}
                onChange={(value) => {
                  setTimeSlots(value);
                  setSelectError(false);
                }}
                styles={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    borderColor: selectError && "#f46a6a",
                    ":hover": {
                      borderColor: selectError && "#f46a6a"
                    },
                  }),
                }}
              />
              {selectError && <div className="text-danger small">Please select at least one time slot.</div>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="mt-2">Qualifications</h5>
            <hr />
            <div className="d-flex align-items-center">
              <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                <EAsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions={searchOptions}
                  onFocus={() => dispatch(chainedOptionsHms("/hms/staff/qualifications/fetch_all/", null, "fetch_qualifications"))}
                  options={{ required: true, label: "Qualifications" }}
                  selectedOption={selectedQualifications}
                  fetchOptions={searchQualifications}
                  formError={formError?.qualifications}
                  onSelect={updateQualificationSelect}
                  placeholder="Type to search qualifications..."
                  getOptionLabel={e => e?.name}
                  getOptionValue={e => e?.id}
                />
              </div>
              <div onClick={() => toggleQualification(false)} title="Add qualifications" className={`cursor-pointer ms-2 ${formError?.qualifications ? '' : 'mt-3'}`}>
                <i className="bx bx-plus-medical text-success" />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <EAvFieldInput
              field="registration_number"
              type="text"
              value={doctor?.registration_number}
              isError={formError?.registration_number}
              options={{ ...options?.form_schema?.registration_number, label: "Registration number" }}
            />
          </Col>
          <Col md={6} sm={12}>
            <EAvFieldNumber
              field="experience"
              value={doctor?.experience}
              isError={formError?.experience}
              options={{ required: true, label: "Experience", min_value: "0" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="mt-2">Additional Information</h5>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <EAvFieldInput
              field="languages_known"
              type="text"
              value={doctor?.languages_known}
              isError={formError?.languages_known}
              options={{ label: "Known languages", max_length: 256 }}
            />
          </Col>
          <Col md={6} sm={12}>
            <EAvFieldInput
              field="extra_specializations"
              type="text"
              value={doctor?.extra_specializations}
              isError={formError?.extra_specializations}
              options={{ label: "Extra specializations", max_length: 160 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EAvFieldGenericInput
              value={doctor?.user_data?.address}
              isError={formError?.address}
              {...modifiedFormSchema?.address}
              label={`Address ${modifiedFormSchema?.address?.required ? "*" : ""}`}
              type="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Button color="primary" className="btn-sm mb-3" onClick={() => toggleSignature(false)}>
                {signature ? "Modify Signature" : "Add Signature"}
              </Button>
            </div>
          </Col>
        </Row>
        <RequiredFieldsMessage />
        <Row className="justify-content-end">
          <Col sm={9}>
            <div>
              {[`update_${apiStartKey}_updated`, `post_${apiStartKey}_created`].includes(apiKey) ? (
                <>
                  <Button color="primary" className="float-end" disabled>
                    {isEmpty(doctor) ? "Add" : "Modify"} Doctor
                    <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                  </Button>
                  {historyProp && <GoBack disabled />}
                </>
              ) : (
                <>
                  <Button type="submit" color="primary" className="float-end">
                    {isEmpty(doctor) ? "Add" : "Modify"} Doctor
                  </Button>
                  {historyProp && <GoBack historyProp={historyProp} />}
                </>
              )}
            </div>
          </Col>
        </Row>
      </AvForm>

      <SingleFieldCRUModal
        modalLabel={"Qualification"}
        modal={modalQualification}
        toggle={toggleQualification}
        rudFlag={1}
        apiStartKey={"doctor_qualification"}
        apiEndpoint={"/hms/staff/qualifications/"}
      />

      <ConfirmationDialog
        activeOPAlert={activeOPAlert}
        setActiveOPAlert={setActiveOPAlert}
        state={state}
        onConfirm={onConfirm}
      />

      <DigitalSignature
        modal={modalSignature}
        signature={signature}
        toggle={toggleSignature}
        onSave={handleSaveSignature}
      />
    </div>
  )
}

export const DoctorCRUModal = ({ modal, toggle, rudFlag, size = "lg", apiStartKey }) => {
  const dispatch = useDispatch()
  const { options, hmsObject, formError, apiKey, postResponse, optionsChainedHms } = useSelector((state) => state.Hms)
  const formRef = useRef(null)
  const [timeSlots, setTimeSlots] = useState([])
  const [selectError, setSelectError] = useState(false)
  const [phonenumber, setPhonenumber] = useState(null)
  const [doctor, setDoctor] = useState({})
  const [searchOptions, setSearchOptions] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState(null)
  const [modalQualification, setModalQualification] = useState(false)
  const [modalSignature, setModalSignature] = useState(false)
  const [phoneError, setPhoneError] = useState('')
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [roleDefaultOptions, setRoleDefaultOptions] = useState([])
  const [selectedRoles, setSelectedRoles] = useState(null)
  const [signature, setSignature] = useState(null)
  const [age, setAge] = useState({ dob: null, years: null, months: null, days: null })
  const [state, setState] = useState({ title: '', message: '', isExist: false, full_name: '', username: null })
  const [formOptions, setFormOptions] = useState(null)
  const [modifiedFormSchema, setModifiedFormSchema] = useState(null)
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [customError, setCustomError] = useState(null)
  const [addMoreField, setAddMoreField] = useState(false)

  const validateTimeSlots = () => {
    if (timeSlots === null || timeSlots.length === 0) {
      setSelectError(true)
      return false
    }
    setSelectError(false)
    return true
  }

  useEffect(() => {
    if (modal) {
      dispatch(getHmsOptions("/hms/staff/doctors/?options=formschema", "doctor_form_options", false, true))
    }
  }, [modal])

  useEffect(() => {
    setCustomError(formError)
  }, [formError])

  useEffect(() => {
    if (apiKey === "doctor_form_options") {
      setFormOptions(options?.form_schema)
      setModifiedFormSchema(formSchemaConversionAvField(options?.form_schema))
    }
    if (apiKey === `${apiStartKey}_created`) {
      doctorToggle()
      toastr.success("Doctor created successfully")
    }
    if (apiKey === `${apiStartKey}_updated`) {
      doctorToggle()
      toastr.success("Doctor updated successfully")
    }
    if (apiKey === "get_doctor") {
      setDoctor(hmsObject)
      if (hmsObject?.user_data?.dob) {
        setAge({
          dob: hmsObject.user_data.dob,
          years: hmsObject.user_data.age,
          months: moment().diff(hmsObject.user_data.dob, "months"),
          days: moment().diff(hmsObject.user_data.dob, "days"),
        })
      }
      setTimeSlots(hmsObject.time_slots)
      setSelectedQualifications(hmsObject.qualifications)
      setSelectedRoles(hmsObject.user_data.groups)
      handleSaveSignature(hmsObject.signature)
    }
    if (apiKey === `${apiStartKey}_qualification_created`) {
      setSelectedQualifications(selectedQualifications === null ? [postResponse] : [...selectedQualifications, postResponse])
    }
    if (apiKey === "fetch_roles") {
      setRoleDefaultOptions(optionsChainedHms?.results)
    }
    if (apiKey === "fetch_qualifications") {
      setSearchOptions(optionsChainedHms)
    }
    if (formError?.detail && apiKey.startsWith(`fail_${apiStartKey}`)) {
      setActiveOPAlert(true)
      const [code, username, full_name] = formError?.detail.split("_-_")
      setState((prevState) => ({ ...prevState, ...errorMappings[code], username, full_name }))
      setSelectedPerson({ username, full_name })
    }
  }, [apiKey])

  var suTo = null
  const searchRoles = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/roles/?search=${value}`).then((resp) => {
          callback(resp?.results)
        })
      }, 1000)
    }
  }

  const searchQualifications = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/qualifications/fetch_all/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 1000)
    }
  }

  function toggleQualification(def = modalQualification) {
    setModalQualification(!def)
  }

  const handleSubmit = (event, values) => {
    if (!phonenumber) {
      setPhoneError('This field is invalid')
      return
    }
    setPhoneError('')
    const isValid = validateTimeSlots()
    if (isValid) {
      values.qualifications = !isEmpty(selectedQualifications) ? selectedQualifications?.map(qualification => qualification?.id) : null
      values.signature = signature
      values.user_post = {
        full_name: values.full_name,
        username: values.username,
        email: values.email,
        phone_number: phonenumber,
        gender: values.gender,
        dob: age?.dob,
        groups: !isEmpty(selectedRoles) ? selectedRoles?.map(role => role?.id) : [],
        address: values.address
      }
      delete values.full_name
      delete values.username
      delete values.email
      delete values.phone_number
      delete values.gender
      delete values.dob
      delete values.address
      values.specializations = [values.specializations]
      values.time_slots = timeSlots
      let doctorID = doctor.id
      if (state.isExist) {
        delete values.user_post
        values.username = state?.username || selectedPerson?.username
      }
      if (addMoreField) {

      }
      if (doctorID) {
        dispatch(updateHms("/hms/staff/doctors/", doctorID, values, `${apiStartKey}_updated`))
      } else {
        dispatch(postHms('/hms/staff/doctors/', values, `${apiStartKey}_created`))
      }
    }
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }))
    setActiveOPAlert(false)
    formRef.current.submit()
  }

  const toggleSignature = (def = modalSignature) => {
    setModalSignature(!def)
  }

  const handleSaveSignature = (signatureData) => {
    setSignature(signatureData)
  }

  const doctorToggle = () => {
    setCustomError(null)
    setAddMoreField(false)
    setSelectedRoles(null)
    setTimeSlots([])
    setSelectedQualifications(null)
    setState({ title: '', message: '', isExist: false, full_name: '', username: null })
    toggle()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={doctorToggle} backdrop="static" scrollable={true} size={size}>
        <ModalHeader toggle={doctorToggle} tag="h4">
          {rudFlag === 0 ? "Modify Doctor" : rudFlag === 1 ? "Add Doctor" : ""}
        </ModalHeader>
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit} ref={formRef}>
          <ModalBody style={{ maxHeight: "78vh", overflowY: "auto" }}>
            {apiKey === "options_doctor_form_options" ? (
              <Loader />
            ) : (
              <>
                <>
                  <Row>
                    <Col>
                      <EAvFieldGenericInput
                        value={doctor?.user_data?.full_name}
                        isError={customError?.full_name}
                        {...modifiedFormSchema?.full_name}
                        label={`Doctor name ${modifiedFormSchema?.full_name?.required ? "*" : ""}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <EAvFieldGenericInput
                        value={doctor?.user_data?.username}
                        isError={customError?.username}
                        {...modifiedFormSchema?.username}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <PhonenumberInput
                          label="Mobile"
                          name="phone_number"
                          value={doctor?.user_data?.phone_number}
                          error={customError?.phone_number || phoneError}
                          required={true}
                          updateValue={setPhonenumber}
                          onFocus={() => handlePhonenumberFocus(setPhoneError)}
                          onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                        />
                      </div>
                    </Col>
                    <Col>
                      <EAvFieldGenericInput
                        value={doctor?.user_data?.email}
                        isError={customError?.email}
                        {...modifiedFormSchema?.email}
                        label={`Email ${modifiedFormSchema?.email?.required ? "*" : ""}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <EAvFieldSelect
                        field="gender"
                        value={doctor?.user_data ? doctor?.user_data?.gender : "MALE"}
                        isError={customError?.gender}
                        options={{ label: "Gender", required: true }}
                        choices={<>
                          <option>-------Select an option-------</option>
                          {(options.form_schema && options.form_schema.gender) && options.form_schema.gender.choices.map((choice, cidx) =>
                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                          )}
                        </>}
                      />
                    </Col>
                    {addMoreField && (
                      <Col>
                        <EAvFieldDOB
                          age={age}
                          setAge={setAge}
                          required={formOptions?.dob?.required}
                        />
                      </Col>
                    )}
                  </Row>
                  {addMoreField && (
                    <Row>
                      <Col>
                        <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                          <EAsyncSelect
                            isMulti
                            cacheOptions
                            defaultOptions={roleDefaultOptions}
                            onFocus={() => dispatch(chainedOptionsHms("/hms/roles/", null, "fetch_roles"))}
                            options={formOptions?.groups}
                            selectedOption={selectedRoles}
                            fetchOptions={searchRoles}
                            formError={customError?.groups}
                            onSelect={(role) => setSelectedRoles(role)}
                            placeholder="Type to search roles..."
                            getOptionLabel={e => e?.name}
                            getOptionValue={e => e?.id}
                            helpMessage={formOptions?.groups?.help_text}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
                <Row>
                  <Col>
                    <h5 className="mt-2">Professional Information</h5>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <EAvFieldSelect
                      field="specializations"
                      value={doctor?.specializations && doctor?.specializations[0]}
                      isError={customError?.specializations}
                      options={formOptions?.specializations}
                      choices={<>
                        <option>-------Select an option-------</option>
                        {formOptions?.specializations?.choices.map((choice, cidx) =>
                          <option value={choice.id} key={cidx}>{choice.name}</option>
                        )}
                      </>}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <EAvFieldGenericInput
                      value={doctor?.fee}
                      isError={customError?.fee}
                      {...modifiedFormSchema?.fee}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className={selectError ? "text-danger" : ""}>Schedules</Label>
                      <Select
                        closeMenuOnSelect={false}
                        value={timeSlots}
                        isMulti
                        options={formOptions?.time_slots?.choices}
                        getOptionLabel={e => moment(e, 'HH:mm:ss').format('hh:mm A')}
                        getOptionValue={e => e}
                        onChange={(value) => {
                          setTimeSlots(value)
                          setSelectError(false)
                        }}
                        styles={{
                          control: (baseStyle) => ({
                            ...baseStyle,
                            borderColor: selectError && "#f46a6a",
                            ":hover": {
                              borderColor: selectError && "#f46a6a"
                            },
                          }),
                        }}
                      />
                      {selectError && <div className="text-danger small">Please select at least one time slot.</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 className="mt-2">Qualifications</h5>
                    <hr />
                    <div className="d-flex align-items-center">
                      <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                        <EAsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions={searchOptions}
                          onFocus={() => dispatch(chainedOptionsHms("/hms/staff/qualifications/fetch_all/", null, "fetch_qualifications"))}
                          options={{ required: true, label: "Qualifications" }}
                          selectedOption={selectedQualifications}
                          fetchOptions={searchQualifications}
                          formError={customError?.qualifications}
                          onSelect={(value) => setSelectedQualifications(value)}
                          placeholder="Type to search qualifications..."
                          getOptionLabel={e => e?.name}
                          getOptionValue={e => e?.id}
                        />
                      </div>
                      <div onClick={() => toggleQualification(false)} title="Add qualifications" className={`cursor-pointer ms-2 ${customError?.qualifications ? '' : 'mt-3'}`}>
                        <i className="bx bx-plus-medical text-success" />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <EAvFieldGenericInput
                      value={doctor?.registration_number}
                      isError={customError?.registration_number}
                      {...modifiedFormSchema?.registration_number}
                      label={`Registration number ${modifiedFormSchema?.registration_number?.required ? "*" : ""}`}
                    />
                  </Col>
                  {addMoreField && (
                    <Col md={6} sm={12}>
                      <EAvFieldGenericInput
                        value={doctor?.experience}
                        isError={customError?.experience}
                        {...modifiedFormSchema?.experience}
                        label={`Experience ${modifiedFormSchema?.experience?.required ? "*" : ""}`}
                      />
                    </Col>
                  )}
                </Row>
                {addMoreField && (
                  <>
                    <Row>
                      <Col>
                        <h5 className="mt-2">Additional Information</h5>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <EAvFieldGenericInput
                          value={doctor?.languages_known}
                          isError={customError?.languages_known}
                          {...modifiedFormSchema?.languages_known}
                          label={`Known languages ${modifiedFormSchema?.languages_known?.required ? "*" : ""}`}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <EAvFieldGenericInput
                          value={doctor?.extra_specializations}
                          isError={customError?.extra_specializations}
                          {...modifiedFormSchema?.extra_specializations}
                          label={`Extra specializations ${modifiedFormSchema?.extra_specializations?.required ? "*" : ""}`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EAvFieldGenericInput
                          value={doctor?.user_data?.address}
                          isError={customError?.address}
                          {...modifiedFormSchema?.address}
                          label={`Address ${modifiedFormSchema?.address?.required ? "*" : ""}`}
                          type="textarea"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <Button color="primary" className="btn-sm mb-3" onClick={() => toggleSignature(false)}>
                            {signature ? "Modify Signature" : "Add Signature"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <RequiredFieldsMessage />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Row>
                <Col>
                  <div
                    className="text-primary mb-1 cursor-pointer d-flex align-items-center"
                    onClick={() => setAddMoreField(!addMoreField)}
                  >
                    <span>{addMoreField ? 'Show less' : 'Add more fields'}</span>
                    <i className={`bx font-size-18 ms-1 ${addMoreField ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <SubmitLoaderButton
                    type="submit"
                    size="sm"
                    loading={apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created`}
                  >
                    Submit
                  </SubmitLoaderButton>
                </Col>
              </Row>
            </div>
          </ModalFooter>
        </AvForm>
      </Modal>

      <SingleFieldCRUModal
        modalLabel={"Qualification"}
        modal={modalQualification}
        toggle={toggleQualification}
        rudFlag={1}
        apiStartKey={`${apiStartKey}_qualification`}
        apiEndpoint={"/hms/staff/qualifications/"}
      />

      <ConfirmationDialog
        activeOPAlert={activeOPAlert}
        setActiveOPAlert={setActiveOPAlert}
        state={state}
        onConfirm={onConfirm}
      />

      <DigitalSignature
        modal={modalSignature}
        signature={signature}
        toggle={toggleSignature}
        onSave={handleSaveSignature}
      />
    </div>
  )
}