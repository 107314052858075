import { AvCheckbox, AvCheckboxGroup, AvField, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { searchBarIconSizeFetch } from "components/Common/common";
import AsyncSelect from "react-select/async";
import { Label, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, Input } from "reactstrap";
import InputMask from "react-input-mask"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

// for all components
export const EAvFieldInput = ({ mb = true, field, type = 'text', value = '', isError, className = "", options = {}, marginTop = '-14px', ...rest }) => {
  /* This component will accept 3 different types : text, email, and date.*/
  const { label = '', required = false, max_length: maxLength = undefined, placeholder = "" } = options;

  const validateRules = {
    required: { value: required },
  };

  if (maxLength !== undefined) {
    validateRules.maxLength = { value: maxLength }
  }
  if (type === 'date') {
    if (rest?.dateRange) validateRules.dateRange = rest?.dateRange
  }
  if (type === 'number') {
    rest.onWheel = (e) => e.target.blur();
  }

  return (
    <div className={mb ? "mb-3" : "mb-n3"}>
      <AvField
        id={`id_${field}`}
        className={isError ? ` ${className} is-invalid` : className}
        type={type}
        name={field}
        value={value}
        label={label && <span className={isError ? 'text-danger' : ''}>{label} {required && " *"}</span>}
        validate={validateRules}
        placeholder={placeholder}
        {...rest}
      />
      {isError && (
        <div className="text-danger small" style={{ marginTop: marginTop }}>
          {isError}
        </div>
      )}
    </div>
  );
};

export const EInputDropdown = ({ name, value = '', formError, options = {}, dropdownOptions = [], setDropdownValue, setInputValue, dropdownValue, inputValue, ...rest }) => {
  const { label = '', required = false, placeholder = "" } = options

  return (
    <div className="mb-3">
      <label htmlFor={`id_${name}`}>
        <span className={formError ? "text-danger" : ""}>
          {label} {required && " *"}
        </span>
      </label>
      <InputGroup>
        <UncontrolledDropdown>
          <DropdownToggle caret outline className={`btn btn-outline-secondary ${formError ? "border-danger" : ""}`}          >
            {dropdownValue || "---"}
          </DropdownToggle>
          <DropdownMenu>
            {dropdownOptions.map((item) => (
              <DropdownItem key={item} onClick={() => setDropdownValue(item)}>
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <Input
          className={formError ? "is-invalid" : ""}
          type="text"
          id={`id_${name}`}
          name={name}
          value={inputValue}
          onChange={setInputValue}
          aria-label="Text input with dropdown button"
          placeholder={placeholder}
          required={required}
          {...rest}
        />
      </InputGroup>
      {formError && (
        <div className="text-danger small">
          {formError}
        </div>
      )}
    </div>
  )
}

export const EAvFieldMask = ({ mask = "9-9-9", field, type = 'text', value = '', formError, className = "", options = {}, ...rest }) => {
  const { label = '', required = false, max_length: maxLength = undefined, placeholder = "" } = options;

  const validateRules = {
    required: { value: required },
  };

  if (maxLength !== undefined) {
    validateRules.maxLength = { value: maxLength }
  }
  if (type === 'date') {
    if (rest?.dateRange) validateRules.dateRange = rest?.dateRange
  }

  return (
    <div className="mb-3">
      {label && <Label className={formError ? "text-danger" : ""}>{label} {required && " *"}</Label>}
      <InputMask
        id={`id_${field}`}
        mask={mask}
        className={formError ? ` ${className} is-invalid form-control input-color` : `${className} form-control input-color`}
        name={field}
        type={type}
        placeholder={placeholder}
        {...rest}
      ></InputMask>
      {formError && (
        <div className="text-danger small">
          {formError}
        </div>
      )}
    </div>
  );
};

export const EAvFieldSelect = ({ mb = true, field, value = '', isError, className = "", options = {}, choices, onChange, ...rest }) => {
  const { label = '', required = false } = options;
  return (
    <div className={mb ? "mb-3" : "mb-n3"}>
      <AvField
        id={`id_${field}`}
        className={isError ? ` ${className} is-invalid` : className}
        type="select"
        name={field}
        value={value}
        label={label && <span className={isError ? 'text-danger' : ''}>{label} {required && " *"}</span>}
        validate={{ required: { value: required } }}
        onChange={onChange}
        {...rest}
      >
        {choices}
      </AvField>
      {isError && (
        <div className="text-danger small" style={{ marginTop: '-14px' }}>
          {isError}
        </div>
      )}
    </div>
  );
};

export const EAvFieldNumber = ({ mb = true, field, value = '', isError, className = "", options = {}, marginTop = '-14px', helperComponent, ...rest }) => {
  const { label = '', required = false, min_value: minValue = null, max_value: maxValue = null } = options;

  return (
    <div className={mb ? "mb-3" : "mb-n3"}>
      <AvField
        id={`id_${field}`}
        className={isError ? ` ${className} is-invalid` : className}
        type="number"
        name={field}
        value={value}
        label={label && <span className={isError ? 'text-danger' : ''}>{label} {required && " *"}</span>}
        validate={{ required: { value: required } }}
        min={minValue}
        max={maxValue}
        onWheel={(e) => e.target.blur()}
        {...rest}
      />
      {isError && (
        <div className="text-danger small" style={{ marginTop: marginTop }}>
          {isError}
        </div>
      )}
      {helperComponent}
    </div>
  );
};

export const EAvFieldRadio = ({ name, choices, defaultChecked, className = "", formError, options = {}, ...rest }) => {
  const { label = '', required = false } = options;

  return (
    <>
      <Label className={formError ? "text-danger" : ""}>{label} {required && " *"}</Label>
      <AvRadioGroup
        inline
        id={`id_${name}`}
        name={name}
        required={required}
        defaultChecked={defaultChecked}
        {...rest}
      >
        {choices.map((choice) => (
          <AvRadio
            key={choice.value}
            className={formError ? ` ${className} is-invalid` : className}
            label={choice.label}
            value={choice.value}
          />
        ))}
      </AvRadioGroup>
      {formError && (
        <div className="text-danger small" style={{ marginTop: '-14px' }}>
          {formError}
        </div>
      )}
    </>
  );
};

export const EAvFieldCheck = ({ name, options = {}, className = "", formError, ...rest }) => {
  const { label = '', required = false } = options;

  return (
    <>
      <AvGroup>
        <AvInput type="checkbox" name={name} id={name} {...rest} />
        <Label className={formError ? "text-danger ms-2" : "ms-2"} for={name}> {label} {required && " *"}</Label>
      </AvGroup>
      {formError && (
        <div className="text-danger small" style={{ marginTop: '-22px' }}>
          {formError}
        </div>
      )}
    </>
  );
};

export const EAvFieldMultiCheck = ({ name, choices, defaultChecked, className = "", formError, options = {}, ...rest }) => {
  const { label = '', required = false } = options;

  return (
    <>
      <Label className={formError ? "text-danger" : ""}>{label} {required && " *"}</Label>
      <AvCheckboxGroup
        inline
        name={name}
        required={required}
        defaultChecked={defaultChecked}
        {...rest}
      >
        {choices.map((choice) => (
          <AvCheckbox
            key={choice.value}
            className={formError ? ` ${className} is-invalid` : className}
            label={choice.label}
            value={choice.value}
            disabled={choice.disabled}
          />
        ))}
      </AvCheckboxGroup>
      {formError && (
        <div className="text-danger small" style={{ marginTop: '-14px' }}>
          {formError}
        </div>
      )}
    </>
  );
};

export const EAsyncSelect = ({ selectedOption, fetchOptions, formError, onSelect, placeholder, getOptionLabel, getOptionValue, defaultValue, options, additionalStyles, ...rest }) => {
  const { label = "", required = false } = options || {};

  return (
    <>
      {label && <Label className={formError ? "text-danger" : ""}>{label} {required && " *"}</Label>}
      <AsyncSelect
        value={selectedOption}
        loadOptions={fetchOptions}
        onChange={onSelect}
        classNamePrefix="select2-selection"
        placeholder={placeholder || ""}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        defaultValue={defaultValue}
        styles={{
          singleValue: (styles, { data }) => {
            return {
              ...styles,
              color: "black"
            }
          },
          container: (provided) => ({
            ...provided,
            width: "100%",
          }),
          control: (provided, state) => ({
            ...provided,
            borderColor: formError ? "#eb3443 !important" : rest?.isDisabled ? "#ced4da" : "#eee"
          }),
          valueContainer: (provided) => ({
            ...provided,
            backgroundColor: rest?.isDisabled ? "#eff2f7" : "#fff",
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 999,
          }),
          ...additionalStyles
        }}
        {...rest}
      />
      {
        rest?.helpMessage &&
        <small className="form-text text-muted">{rest?.helpMessage}</small>
      }
      {
        formError &&
        <div className="text-danger small">
          {formError}
        </div>
      }
    </>
  )
}

export const EAvFieldDiscountPercentage = ({ mb = true, field = "discount_percent", value = '', formError, className = "", options = {}, marginTop = '-14px', ...rest }) => {
  const { label = '', required = false } = options;

  return (
    <div className={mb ? "mb-3" : "mb-n3"}>
      <AvField
        id={`id_${field}`}
        className={formError ? ` ${className} is-invalid` : className}
        type="number"
        name={field}
        value={value}
        label={label && <span className={formError ? 'text-danger' : ''}>{label} {required && " *"}</span>}
        defaultValue={"0"}
        validate={{
          required: { value: required },
          pattern: { value: '^[0-9]+(\.[0-9]{1,2})?$', errorMessage: 'Invalid discount format' },
          min: { value: 0, errorMessage: 'Value must be greater than or equal to 0' },
          max: { value: 100, errorMessage: 'Value must be less than or equal to 100' }
        }}
        onWheel={(e) => e.target.blur()}
        {...rest}
      />
      {formError && (
        <div className="text-danger small" style={{ marginTop: marginTop }}>
          {formError}
        </div>
      )}
    </div>
  );
};

// AsyncSearch is intended for the search box.
export const AsyncSearch = ({ placeholder, noOptionsMessage, ...rest }) => {
  /* This component will also accept onChange, loadOptions, onFocus, defaultOptions, getOptionLabel in ...rest.*/

  return (
    <div className="search-box">
      <div className="position-relative">
        <AsyncSelect
          placeholder={placeholder || ""}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => "No Options"}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderRadius: '30px',
              paddingLeft: '30px',
              minHeight: 'calc(1.5em + 1rem + 2px)',
              fontSize: '1.01563rem',
            })
          }}
          {...rest}
        />
        <i className="bx bx-search-alt search-icon" style={searchBarIconSizeFetch()} />
      </div>
    </div>
  )
}

export const ETextEditorInput = ({ formError, className = "", options = {}, ...rest }) => {
  const { label = '', required = false } = options;

  return (
    <div className="mb-3">
      <div className="editor-wrapper">
        {label && <Label className={formError ? "text-danger" : ""}>{label} {required && " *"}</Label>}
        <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
          toolbarStyle={{
            borderRadius: "2px",
            backgroundColor: "#f8f9fa",
            border: "1px solid #E0E0E0",
          }}
          editorStyle={{
            height: rest.height ? rest.height : "100px",
            border: "1px solid #E0E0E0",
            padding: "5px",
            borderRadius: "2px",
            scrollbarWidth: "thin",
          }}
          {...rest}
        />
      </div>
      {formError && (
        <div className="text-danger small">
          {formError}
        </div>
      )}
    </div>
  );
};

export const EAvFieldGenericInput = ({ label, isError, mb = true, ...props }) => {
  /* This component will accept 5 different types : text, number, email, date and textarea.*/

  return (
    props?.name ? (
      <div className={`${mb ? "mb-3" : "mb-n3"} ${isError ? "text-danger" : ""}`}>
        <AvField
          className={isError ? ` ${props?.className || ""} av-invalid is-invalid` : props?.className || ""}
          label={label}
          {...props}
        />
        {isError && (
          <div className="text-danger small" style={{ marginTop: "-14px" }}>
            {isError}
          </div>
        )}
      </div>
    ) : null);
};

// for all components
export const ErrorMessage = ({ error, marginTop = '-14px' }) => {
  if (!error) {
    return null;
  }

  return (
    <div className="text-danger small" style={{ marginTop: marginTop }}>
      {error}
    </div>
  );
}

export const RequiredFieldsMessage = ({ msg = "* fields are mandatory" }) => {
  return msg ? <div className="color-muted mb-1">{msg}</div> : null
}