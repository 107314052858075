import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportDownloadHms } from 'store/actions';
import { handleIncludedKeysError } from 'pages/HMS/common/common';
import { DownloadModal } from 'pages/HMS/common/hms-crud-modals';
import { openPdf } from 'helpers/api_helper';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PreviewPDF = ({ buttonName = "DOWNLOAD REPORT", className, size = "xl", showIcon = true, apiEndpoint, apiStartKey }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);

  const { loading, error, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === `${apiStartKey}_download_report`) {
      setShowModal(true);
    }
    if (error && apiKey.startsWith(`fail_${apiStartKey}_download_report`)) {
      const includedKeys = ['detail']
      handleIncludedKeysError(error, apiKey, `fail_${apiStartKey}_download_report`, includedKeys, false)
    }
  }, [apiKey]);

  const fetchReport = () => {
    dispatch(reportDownloadHms(apiEndpoint, `${apiStartKey}_download_report`))
  }

  return (
    <>
      <div>
        {loading && apiKey === `get_${apiStartKey}_download_report` ? (
          <button type="button" className={className} disabled>
            {buttonName} {" "}<i className="bx bx-loader bx-spin align-middle ms-1"></i>
          </button>
        ) : (
          <button type="button" className={className} onClick={fetchReport}>
            {buttonName} {" "}{showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}
          </button>
        )}
      </div>

      <DownloadModal
        modal={showModal}
        toggle={() => setShowModal(false)}
        apiStartKey={`${apiStartKey}_download_report`}
        size={size}
      />
    </>
  );
}
export default PreviewPDF;


export const ReportDownload = ({url, label="Download Report", type="button", color="primary", disabled=false, showIcon=true, className=window.innerWidth <= 425 ? 'btn-sm me-1' : 'me-1', ...props}) => {
  const [loading, setLoading] = useState(disabled);
  const download = () => {
    setLoading(true)
    openPdf(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      }
    })
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      const newTab = window.open();
      newTab.location = url;
      setTimeout(() => window.URL.revokeObjectURL(url), 5000);
      setLoading(false)
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error)
      setLoading(false)
    })
  };

  return (
    <>
    {type==="text" ?
      <>
        {loading ?
          <p className="text-muted" {...props}> Downloading {showIcon && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}</p>
          :
          <p className="text-muted" {...props} onClick={download}>{label} {showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}</p>
        }
      </>
      : type==="menu-item" ?
      <>
        {loading ? (
          <Link className={`disabled text-muted dropdown-item ${className}`} to={"#"} {...props}>
            {label} {showIcon && <i className="bx bx-loader bx-spin align-middle ms-1" />}
          </Link>
        ) : (
          <Link className={`text-dark dropdown-item ${className}`} to={"#"} onClick={download} {...props}>
            {label} {showIcon && <i className="mdi mdi-download align-middle ms-1" />}
          </Link>
        )}
      </>
      :
      <div>
        {loading ?
          <Button
            color={color}
            className={className}
            disabled={true}
            {...props}
          >
            {label} {showIcon && <i className="bx bx-loader bx-spin align-middle ms-1"></i>}
          </Button>
        :
          <Button
            color={color}
            className={className}
            onClick={download}
            {...props}
          >
            {label} {showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}
          </Button>
        }
      </div>
    }
    </>
  );
}
