import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms } from "store/actions"
import { EAvFieldGenericInput, EAvFieldInput, EAvFieldSelect, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import FlashAlert from "components/Common/flash-message"
import { Loader, SubmitLoaderButton } from "../common/common"
import toastr from "toastr"

export const SupplierCRUDModal = ({ modal, toggle, rudFlag, data, apiStartKey }) => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const { options, formError, apiKey, modifiedFormSchema } = useSelector(state => state.Hms)
  const [mobile, setMobile] = useState(null);
  const [alternateMobile, setAlternateMobile] = useState(null);
  const [customError, setCustomError] = useState(null)

  useEffect(() => { setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      supplierToggle()
      toastr.success("Supplier created successfully")
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      supplierToggle()
      toastr.success("Supplier updated successfully")
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    values.mobile = mobile
    values.alternate_mobile = alternateMobile

    values.address = {
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      district: values.district,
      state: values.state,
      pincode: values.pincode,
    }
    delete values.line1
    delete values.line2
    delete values.city
    delete values.district
    delete values.state
    delete values.pincode

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/pharmacy/suppliers/", data?.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/pharmacy/suppliers/", values, `${apiStartKey}_created`))
    }
  }

  const supplierToggle = () => {
    toggle()
    setCustomError(null)
  }

  return (
    <Modal isOpen={modal} toggle={supplierToggle} backdrop="static" scrollable={true} size="lg">
      <ModalHeader toggle={supplierToggle} tag="h4">
        {rudFlag === 0 ? "Modify Supplier" : rudFlag === 1 ? "Add Supplier" : ""}
      </ModalHeader>
      {["options_suppliers_options", "options_suppliers_detail_options"].includes(apiKey) ? (
        <Loader />
      ) : (
        <>
          <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
            <ModalBody style={{ height: "75vh", overflowY: "auto" }}>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.name}
                    isError={customError?.name}
                    {...modifiedFormSchema?.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.contact_person}
                    isError={customError?.contact_person}
                    {...modifiedFormSchema?.contact_person}
                  />
                </Col>
                <Col>
                  <div className="mb-3">
                    <PhonenumberInput
                      label={options?.form_schema?.mobile?.label}
                      name="mobile"
                      value={data?.mobile}
                      error={customError?.mobile}
                      required={options?.form_schema?.mobile?.required}
                      updateValue={setMobile}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.contact_person_2}
                    isError={customError?.contact_person_2}
                    {...modifiedFormSchema?.contact_person_2}
                  />
                </Col>
                <Col>
                  <div className="mb-3">
                    <PhonenumberInput
                      label={options?.form_schema?.alternate_mobile?.label}
                      name="alternate_mobile"
                      value={data?.alternate_mobile}
                      error={customError?.alternate_mobile}
                      required={options?.form_schema?.alternate_mobile?.required}
                      updateValue={setAlternateMobile}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.email}
                    isError={customError?.email}
                    {...modifiedFormSchema?.email}
                  />
                </Col>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.website}
                    isError={customError?.website}
                    {...modifiedFormSchema?.website}
                    helpMessage="URL must starts with www, http or https"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.gst_number}
                    isError={customError?.gst_number}
                    {...modifiedFormSchema?.gst_number}
                  />
                </Col>
                <Col>
                  <EAvFieldSelect
                    field="customer_type"
                    value={data?.customer_type}
                    isError={customError?.customer_type}
                    options={options?.form_schema?.customer_type}
                    choices={<>
                      <option value="">{"---------Select an option---------"}</option>
                      {options?.form_schema?.customer_type?.choices?.map((choice, cidx) =>
                        <option value={choice.value} key={cidx}>{choice.display_name}</option>
                      )}
                    </>}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.notes}
                    isError={customError?.notes}
                    {...modifiedFormSchema?.notes}
                    type="textarea"
                  />
                </Col>
              </Row>
              <h6 className="mt-4 text-muted">Address</h6>
              <hr />
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.address?.line1}
                    isError={customError?.line1}
                    {...modifiedFormSchema?.line1}
                  />
                </Col>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.address?.line2}
                    isError={customError?.line2}
                    {...modifiedFormSchema?.line2}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <EAvFieldGenericInput
                    value={data?.address?.district}
                    isError={customError?.district}
                    {...modifiedFormSchema?.district}
                  />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <EAvFieldGenericInput
                    value={data?.address?.city}
                    isError={customError?.city}
                    {...modifiedFormSchema?.city}
                  />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <EAvFieldGenericInput
                    value={data?.address?.state}
                    isError={customError?.state}
                    {...modifiedFormSchema?.state}
                  />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <EAvFieldGenericInput
                    value={data?.address?.pincode}
                    isError={customError?.pincode}
                    {...modifiedFormSchema?.pincode}
                  />
                </Col>
              </Row>
              <RequiredFieldsMessage />
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col className="text-end">
                  <SubmitLoaderButton type="Submit" size="sm" loading={[`update_${apiStartKey}_updated`, `post_${apiStartKey}_created`].includes(apiKey)}>
                    SAVE
                  </SubmitLoaderButton>
                </Col>
              </Row>
            </ModalFooter>
          </AvForm>
        </>
      )}
    </Modal>
  )
}


export const MedicineCRUDModal = (props) => {
  const { modal, toggle, rudFlag, options, data } = props
  const formRef = useRef()
  const dispatch = useDispatch()
  const { loading, formError } = useSelector(state => state.Hms)

  const handleValidSubmit = (e, values) => {

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/pharmacy/medicines/", data?.id, values, "medicine-updated"))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/pharmacy/medicines/", values, "medicine-created"))
    }
  }

  const onPaymentModeChange = (e) => {
    document.getElementById("tablets_per_strip").style.display = e.target.value === "Strip" ? "block" : "none";
  }

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable={true} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {rudFlag === 0 ? "Modify Medicine" : rudFlag === 1 ? "Add Medicine" : rudFlag === 2 ? "Delete Medicine" : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={handleValidSubmit}
          ref={formRef}
        >
          {options && (
            <>
              <Row>
                <Col md={8}>
                  <EAvFieldInput
                    field="name"
                    type="text"
                    value={data?.name}
                    isError={formError?.name}
                    options={options?.name}
                  />
                </Col>
                <Col md={4}>
                  <EAvFieldInput
                    field="dosage"
                    type="number"
                    value={data?.dosage}
                    isError={formError?.dosage}
                    options={{ ...options?.dosage, required: false }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldInput
                    field="formula"
                    type="text"
                    value={data?.formula}
                    isError={formError?.formula}
                    options={options?.formula}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <EAvFieldSelect
                    field="type"
                    value={data?.type}
                    isError={formError?.type}
                    options={options?.type}
                    choices={<>
                      <option value={""}>--Select an option--</option>
                      {options?.type?.choices.map((choice, cidx) =>
                        <option value={choice.value} key={cidx}> {choice.display_name} </option>
                      )}
                    </>}
                    onChange={onPaymentModeChange}
                  />
                </Col>
                <Col md={6} id="tablets_per_strip" style={{ display: data?.type === "Strip" ? 'block' : 'none' }}>
                  <EAvFieldInput
                    field="tablets_per_strip"
                    type="number"
                    value={data?.tablets_per_strip}
                    isError={formError?.tablets_per_strip}
                    options={options?.tablets_per_strip}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldInput
                    field="manufacturer"
                    type="text"
                    value={data?.manufacturer}
                    isError={formError?.manufacturer}
                    options={options?.manufacturer}
                  />
                </Col>
              </Row>
              <RequiredFieldsMessage />
              <Row>
                <Col>
                  <div className="text-end mb-2 mt-3">
                    {loading ? (
                      <button disabled className="btn btn-primary btn-sm save-user">
                        Submit <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary btn-sm save-user">
                        Submit
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </AvForm>
      </ModalBody>
    </Modal>
  )
}


export const PharmacyDeleteModal = (props) => {
  const { body, loading, error, toggleDelete, dispatchOperation, deleteModal } = props;
  const dispatch = useDispatch();
  const [deleteEnabled, setDeleteEnabled] = useState(false)

  return (
    <Modal isOpen={deleteModal} toggle={toggleDelete} backdrop="static">
      <ModalHeader toggle={toggleDelete} tag="h4">
        <i className="bx bx-error me-1 text-warning"></i> Warning: Before deleting record, Make sure the below things
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-12">
            {error &&
              <FlashAlert color="danger" delay="10000" className="text-center">{error.detail}</FlashAlert>
            }
            {body}
            <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <div className="mb-3">
              <input
                name="confirmation-text"
                type="text"
                className="form-control"
                required={false}
                onChange={(e) => setDeleteEnabled(e.target.value === "delete permanently")}
              />
            </div>
          </Col>
        </Row>
        <Row className="float-end">
          <Col>
            <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled || loading}
              onClick={() => { dispatch && dispatch(dispatchOperation) }}>
              Yes
            </button>
            <button type="button" className="btn btn-light btn-sm" onClick={toggleDelete}>
              No
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}


export const BlankModal = (props) => {
  const { modal, toggleModal, header, body, footer, scrollable = true, closable = false } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      backdrop="static"
      centered={true}
      scrollable={scrollable}
    >
      <ModalBody>
        {header &&
          <ModalHeader>
            {header}
            {closable &&
              <button
                type="button"
                onClick={toggleModal}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </ModalHeader>
        }
        {body &&
          <div className="modal-body">
            {body}
          </div>
        }
        {footer &&
          <div className="modal-footer">{footer}</div>
        }
      </ModalBody>
    </Modal>
  )
}
