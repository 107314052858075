import React, { Fragment, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal, Badge, Label } from "reactstrap"
import { getHms, getHmsOptions, postHms, resetHmsState, updateHms } from "store/actions";
import { bloodGroupVerboseFetch, formatDateTime, genderVerboseFetch, TableChip } from "components/Common/common";
import { isEmpty } from "lodash";
import { Loader } from "pages/HMS/common/common";
import BillingSummary from "pages/HMS/billing/billing-summary";
import { NestedTable } from "./nested-table";
import toastr from "toastr";
import { get } from "helpers/api_helper";
import { EAvFieldCheck } from "pages/HMS/common/errored-avfields";
import { AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReportDownload } from "components/Common/print-report";

const BookTestDetail = (props) => {
  const dispatch = useDispatch();
  const { loading, error, hmsObject, apiKey, options, updateResponse, postResponse } = useSelector(state => state.Hms);
  const [testObject, setTestObject] = useState({});
  const [testsData, setTestsData] = useState([]);
  const [patient, setPatient] = useState({});
  const [billingDetail, setBillingDetail] = useState(null);
  const [modals, setModals] = useState({ ackModal: false, confirmModal: false, resultModal: false });
  const [confirmEnabled, setConfirmEnabled] = useState(false)
  const [transformedData, setTransformedData] = useState(null)

  useEffect(() => {
    if (apiKey === "test_details") {
      const apiMode = hmsObject?.patient ? "patients" : hmsObject?.outpatient ? "outpatients" : hmsObject?.inpatient ? "inpatients" : null
      const patientID = hmsObject?.patient || hmsObject?.outpatient || hmsObject?.inpatient
      if (apiMode) {
        dispatch(getHms(`/hms/patient/${apiMode}/`, patientID, "patient_details"))
      }
      setTestObject(hmsObject);
      setTestsData(hmsObject.tests_data)

      if (!isEmpty(hmsObject?.tests_data)) {
        Promise.all(hmsObject?.tests_data.map(test =>
          get(`/hms/diagnosis/tests/${test?.test}/all_test_parameters/`).then(response => ({
            status: response.status,
            data: response,
            id: test?.id
          }))
        )).then(results => {
          results.forEach(result => {
            if (result.data) {
              setTestsData(prevTestsData => prevTestsData.map(test => test.id === result.id ? { ...test, parameters: result.data } : test));
            }
          });
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          dispatch(getHms(`/hms/billing/billing/${hmsObject.billing}/`, null, "billing_details"))
        });
      }
    }
    if (apiKey === "patient_details") {
      setPatient(hmsObject?.patient_data || hmsObject);
    }
    if (apiKey === "billing_details") {
      setBillingDetail(hmsObject);
      const updatedTestsData = mergeResults(testsData, testObject?.results);
      setTestsData(updatedTestsData);
    }
    if (apiKey === "status_change") {
      setTestObject((prevState) => ({ ...prevState, status: updateResponse.status }));
    }
    if (apiKey === "book_test_modified") {
      setModals((prevState) => ({ ...prevState, ackModal: true, confirmModal: false, resultModal: false }));
      setTestObject(updateResponse);
    }
    if (apiKey === "send_report") {
      toastr.success(postResponse.detail)
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/book-tests/?options=formschema,table,search", "bt_options"))
    if (props.match.params.testID) dispatch(getHms("/hms/diagnosis/book-tests/", props.match.params.testID, "test_details"))
    return () => dispatch(resetHmsState())
  }, []);

  useEffect(() => {
    const transformedData = testsData?.map(test => {
      const transformedTests = {
        id: test.test,
        name: test.name,
        short_name: test.short_name || null,
        parameters: transformParameters(test?.parameters)
      };
      return transformedTests;
    });
    setTransformedData(transformedData)
  }, [testsData])

  const onStatusChange = (e) => {
    dispatch(updateHms("/hms/diagnosis/book-tests/", testObject.id, { status: e.target.value }, "status_change"))
  }

  const onTableChange = (e, test) => {
    const updateParameters = (parameters) => {
      return parameters.map(parameter => {
        if (parameter.id === test.id) {
          return { ...parameter, result: e.target.value };
        }
        if (parameter.children && parameter.children.length > 0) {
          return { ...parameter, children: updateParameters(parameter.children) };
        }
        return parameter;
      });
    };

    const updatedTestsData = testsData.map(test => ({ ...test, parameters: updateParameters(test.parameters) }));
    setTestsData(updatedTestsData);
  }

  function transformParameters(parameters) {
    return parameters?.map(parameter => {
      const transformedParameter = {
        id: parameter.id,
        name: parameter.name,
        reference_result: parameter.reference_result,
        unit: parameter.unit,
        unit_name: parameter.unit_name,
        result: parameter.result,
      };
      if (parameter.children && parameter.children.length > 0) {
        transformedParameter.children = transformParameters(parameter.children);
      }
      return transformedParameter;
    });
  }

  const mergeResults = (testsData, testObject) => {
    const resultsMap = new Map();

    const addResultsToMap = (parameters, testId) => {
      parameters?.forEach(param => {
        resultsMap.get(testId).set(param.id, param.result || param.children);
        if (param.children) {
          addResultsToMap(param.children, testId);
        }
      });
    };

    testObject?.forEach(test => {
      resultsMap?.set(test?.id, new Map());
      addResultsToMap(test?.parameters, test?.id);
    });

    const updateParameters = (parameters, testId) => {
      return parameters?.map(param => {
        const updatedParam = { ...param };
        if (resultsMap.get(testId)?.has(param.id)) {
          const resultOrChildren = resultsMap.get(testId).get(param.id);
          if (typeof resultOrChildren === 'string') {
            updatedParam.result = resultOrChildren;
          } else if (Array.isArray(resultOrChildren)) {
            updatedParam.children = updateParameters(resultOrChildren, testId);
          }
        }
        if (param.children) {
          updatedParam.children = updateParameters(param.children, testId);
        }
        return updatedParam;
      });
    };

    return testsData.map(test => ({
      ...test,
      parameters: updateParameters(test.parameters, test.test)
    }));
  };

  const handleSubmit = () => {
    if (!isEmpty(transformedData)) {
      dispatch(updateHms("/hms/diagnosis/book-tests/", testObject.id, { results: transformedData, is_finished: true }, "book_test_modified",))
    } else {
      toastr.error("Please add result")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{patient?.user_data?.full_name && `${patient.user_data.full_name} |`} Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "get_test_details" ? (
              <Loader />
            ) : error && !["DIAG_REPORT_DNLD", "TEST_DOES_NOT_EXIST"].includes(error.code) ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col md={12} lg={6}>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Test Details</h5>
                      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-start">
                        {options?.form_schema?.status &&
                          <select
                            value={testObject?.status ? testObject?.status : ""}
                            onChange={onStatusChange}
                            style={{ border: 'none', outline: 'none', backgroundColor: 'transparent', padding: '0' }}
                            className="text-start me-3 text-muted"
                          >
                            {options?.form_schema?.status?.choices?.map((choice, cidx) =>
                              <option value={choice.value} key={cidx}>{choice.display_name}</option>
                            )}
                          </select>
                        }
                        <div className="text-sm-end ms-2">
                          <UncontrolledDropdown direction="down">
                            <DropdownToggle caret color="primary" size="sm">
                              Action <i className="fa fa-caret-down ms-1" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {testObject?.is_finished && (
                                <ReportDownload
                                  label="Download Report"
                                  url={`/hms/diagnosis/book-tests/${testObject.id}/test_report/`}
                                  type="menu-item"
                                />
                              )}
                              <ReportDownload
                                label="Print Invoice"
                                url={`/hms/diagnosis/book-tests/${testObject?.id}/invoice_receipt/`}
                                type="menu-item"
                              />
                              <Link className="dropdown-item" to={`/hms/diagnosis/test/book/${testObject.id}`}>Modify</Link>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="text-sm-end ms-2">
                          <Button
                            type="button"
                            color="primary"
                            className="btn-sm"
                            onClick={() => setModals({ ...modals, resultModal: true })}
                            disabled={isEmpty(testsData)}
                          >
                            Add Results
                          </Button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {patient?.user_data || testObject?.referred_by || testObject?.referred_by_doctor_name || testObject?.sample_collected_by || testObject?.result_requested_through || testObject?.status ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Referred by"
                            value={testObject?.referred_by || testObject?.referred_by_doctor_name}
                          />
                          <TableChip
                            label="Sample collected by"
                            value={testObject?.sample_collected_by}
                          />
                          {testObject?.result_requested_through === "Email" && (
                            <TableChip
                              label="Email sent"
                              value={testObject?.report_email_sent ? "Yes" : "No"}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Result requested by"
                            value={testObject?.result_requested_through}
                          />
                          {testObject?.result_delivery && (
                            <TableChip
                              label="Result delivery"
                              value={testObject?.result_delivery}
                            />
                          )}
                          <TableChip
                            label="Status"
                            value={testObject?.status}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="w-100 me-1">
                            <Label>Tests</Label>
                            <p>
                              {!isEmpty(testsData) ? (testsData.map((item, index) => (
                                <Link
                                  to="#"
                                  className="badge badge-soft-primary font-size-11 me-1 ms-1 mb-2 p-1 cursor-pointer"
                                  key={index}
                                >
                                  {item.name} {item?.short_name ? `(${item.short_name})` : ""}
                                </Link>
                              ))) : (
                                <p className="text-muted">NA</p>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Finished"
                            value={testObject?.is_finished ? (
                              <Badge pill color="success">Yes</Badge>
                            ) : (
                              <Badge pill color="danger">No</Badge>
                            )}
                          />
                          <TableChip
                            label="Module"
                            value={testObject?.patient ? (
                              <Badge pill color="info">Patient</Badge>
                            ) : testObject?.outpatient ? (
                              <Badge pill color="primary">Outpatient</Badge>
                            ) : testObject?.inpatient ? (
                              <Badge pill color="success">Inpatient</Badge>
                            ) : (
                              <Badge pill color="secondary">Direct</Badge>
                            )}
                          />
                          <TableChip defaultIfNull=""></TableChip>
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Test details not existed</p>
                    )}
                    <BillingSummary billingDetail={billingDetail} />
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Patient Information</h5>
                    </div>
                    <hr />
                    {patient?.user_data ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Patient name"
                            value={`${patient?.honorific} ${patient?.user_data?.full_name}`}
                          />
                          <TableChip
                            label="Patient ID"
                            value={patient?.uid}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Gender"
                            value={genderVerboseFetch(patient?.user_data?.gender)}
                          />
                          <TableChip
                            label="Marital status"
                            value={patient?.marital_status ? "Married" : "Unmarried"}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Date of birth"
                            value={formatDateTime(patient?.user_data?.dob, "MMM D, YYYY")}
                          />
                          <TableChip
                            label="Age"
                            value={patient.user_data.age ? `${patient.user_data.age} years` : null}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Mobile"
                            value={patient?.user_data?.phone_number}
                          />
                          <TableChip
                            label="Email"
                            value={patient?.user_data?.email}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Blood group"
                            value={bloodGroupVerboseFetch(patient?.blood_group)}
                          />
                        </div>
                      </>
                    ) : testObject?.patient_data ? (
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Patient name"
                          value={`${testObject?.patient_data?.patient_name}`}
                        />
                      </div>
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modals.resultModal}
        toggle={() => setModals(prevModals => ({ ...prevModals, resultModal: false }))}
        backdrop={'static'} id="staticBackdrop"
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Test Results</h5>
          <button type="button" className="btn-close" onClick={() => setModals(prevModals => ({ ...prevModals, resultModal: false }))} aria-label="Close" />
        </div>
        <div className="modal-body" style={{ maxHeight: "74vh" }}>
          {!isEmpty(testsData) ? testsData.map((test, index) => (
            <div key={index}>
              <Fragment>
                <NestedTable test={test} index={index} idx={index} handleChange={onTableChange} />
              </Fragment>
            </div>
          )) : (
            <p className="text-center text-danger" scope="col" colSpan="4">Tests not existed</p>
          )}
        </div>
        <div className="modal-footer">
          {!isEmpty(testsData) && (
            <button className="btn btn-primary save-user" onClick={() => setModals(prevModals => ({ ...prevModals, confirmModal: true }))}>
              Submit
            </button>
          )}
        </div>
      </Modal>

      <Modal isOpen={modals.confirmModal} toggle={() => setModals(prevModals => ({ ...prevModals, confirmModal: false }))} backdrop={'static'} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Confirm Test Completion</h5>
          <button type="button" className="btn-close" onClick={() => setModals(prevModals => ({ ...prevModals, confirmModal: false }))} aria-label="Close" />
        </div>
        <div className="modal-body">
          <AvForm>
            <EAvFieldCheck
              name="confirm"
              options={{ label: "I confirm that the entered test results are accurate." }}
              onChange={(e) => setConfirmEnabled(e.target.checked)}
            />
          </AvForm>
        </div>
        <div className="modal-footer">
          {loading && apiKey === `update_book_test_modified` ? (
            <button disabled className="btn btn-primary save-user">
              Confirm <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1" />
            </button>
          ) : (
            <button type="button" className="btn btn-primary save-user" disabled={!confirmEnabled} onClick={handleSubmit}>
              Confirm
            </button>
          )}
        </div>
      </Modal>

      <Modal isOpen={modals.ackModal} toggle={() => setModals(prevModals => ({ ...prevModals, ackModal: false }))} size="lg">
        <div className="modal-header">
          <button type="button" className="btn-close" onClick={() => setModals(prevModals => ({ ...prevModals, ackModal: false }))} aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="p-2">
            <div className="text-center">
              <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
              <div className="p-2 mt-2">
                <h4>Results submitted successfully</h4>
                <div className="mt-4">
                  <div className="d-flex justify-content-center flex-column">
                    <ReportDownload
                      label="Download Report"
                      url={`/hms/diagnosis/book-tests/${testObject.id}/test_report/`}
                    />
                    <div className="mt-2">
                      {testObject?.result_requested_through === "Email" &&
                        <AvForm>
                          <EAvFieldCheck
                            name={"email"}
                            options={{ label: "Send email" }}
                            onChange={(e) => {
                              if (e.target.checked) {
                                dispatch(postHms(`/hms/diagnosis/book-tests/${testObject.id}/send_notification/`, { [e.target.name]: e.target.checked }, "send_report", false))
                              }
                            }}
                            disabled={loading && apiKey === "post_send_report" || apiKey === "send_report"}
                          />
                        </AvForm>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment >
  );
};

export default BookTestDetail;
