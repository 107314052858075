import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal } from "../common/hms-crud-modals"
import { ProcedureCRUDModal } from "../patientmanagement/treatment/modal"
import { isEmpty } from "lodash"

const Procedure = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error, formError } = useSelector(state => state.Hms)
  const [modal, setModal] = useState(false)
  const [procedure, setProcedure] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [procedureData, setProcedureData] = useState({})
  const [customError, setCustomError] = useState()

  useEffect(() => { if (formError) setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === "procedure_list") {
      setProcedureData(hmsList)
    }
    if (apiKey === "procedure_created") {
      setProcedureData(hmsList)
    }
    if (apiKey === "procedure_updated") {
      setProcedureData(hmsList)
    }
    if (apiKey === "procedure_deleted") {
      setProcedureData(hmsList)
      toggleDelete()
      toastr.success(`Procedure ${procedure.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/procedures/?options=formschema, table, search"))
    dispatch(listHms("/hms/diagnosis/procedures/", null, "procedure_list"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  const columns = [{
    dataField: "name",
    text: "procedure Type",
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.name}</Link>
    )
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  const handleEdit = procedure_object => {
    setProcedure(procedure_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = procedure_object => {
    setProcedure(procedure_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setProcedure({})
    setRUDFlag(1)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
    setCustomError(null)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/diagnosis/procedures/', decodeURIComponent(urlParams).replace("?", ""), "procedure_list"))
      setProcedureData({})
    } else {
      dispatch(listHms('/hms/diagnosis/procedures/', null, "procedure_list"))
      setProcedureData({})
    }
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Create
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {error ? (
              <p className="text-center text-danger">{error?.detail}</p>
            ) : (
              <Col xs="12">
                <Card>
                  {(!isEmpty(columns)) && (
                    <GenericTable
                      urlParams={urlParams}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No records created"}
                      data={procedureData}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                      removeQueryParams
                    />
                  )}
                </Card>
              </Col>
            )}
          </Row>

          <HmsDeleteModal
            body={<>
              <ul>
                <li>If procedure type is removed. You cannot get it back</li>
                <li>If any data linked to this record will be vanished</li>
              </ul>
            </>}
            loading={loading}
            error={error}
            toggleDelete={toggleDelete}
            dispatchOperation={deleteHms("/hms/diagnosis/procedures/", procedure?.id, "procedure_deleted")}
            deleteModal={deleteModal}
          />

          <ProcedureCRUDModal
            modal={modal}
            toggle={toggle}
            rudFlag={rudFlag}
            options={options?.form_schema}
            customError={customError}
            data={procedure}
            prepend
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Procedure;