import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Row, Col, Button, Label, ButtonGroup, Table, Badge } from "reactstrap"
import { chainedOptionsHms, getHms, getHmsOptions, postHms, resetHmsState, updateHms } from "store/hms/actions"
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { TableChip } from "components/Common/common"
import { get } from "helpers/api_helper"
import { EAsyncSelect, EAvFieldCheck, EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import toastr from "toastr"
import { ChequeSection, Loader, PatientInformation, floatRoundDown, formatPercentage, handleKeyDown } from "../common/common"
import { ReportDownload } from "components/Common/print-report"
import { INPATIENT_SEARCH_PLACEHOLDER, OUTPATIENT_SEARCH_PLACEHOLDER, PATIENT_TYPE_DIRECT_PLACEHOLDER } from "../common/constants"

const MedicineSale = (props) => {
  const dispatch = useDispatch()
  const { error, options, hmsObject, formError, apiKey, hmsSingleObject, actionStatus, optionsChainedHms } = useSelector(state => state.Hms)
  const [apiMode, setApiMode] = useState("direct")
  const [patient, setPatient] = useState(null)
  const [medicineObj, setMedicineObj] = useState({})
  const [medicineList, setMedicineList] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, medicine: false })
  const [isModifying, setIsModifying] = useState(props.match.params.medID)
  const [billing, setBilling] = useState({
    subTotal: 0,
    discountPercentage: 0,
    discountAmount: 0,
    grandTotal: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    cgstPercentage: 0,
    sgstPercentage: 0,
    paymentStatus: true,
    paymentMode: "",
    chequeNumber: null,
    chequeDate: null,
    includePendingAmount: false,
    totalPayableAmount: 0,
    customerName: "",
  })

  useEffect(() => {
    dispatch(getHmsOptions("/hms/billing/billing/?options=formschema", "medicine_options", true))
    if (props.match.params.medID) dispatch(getHms("/hms/pharmacy/medicine_sales/", props.match.params.medID, "medicine_sale"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "medicine_sale") {
      const apiMode = hmsObject?.outpatient ? "outpatients" : hmsObject?.inpatient ? "inpatients" : "direct"
      const billing = hmsObject?.billing
      setApiMode(apiMode)
      setMedicineObj(hmsObject)
      dispatch(chainedOptionsHms(`/hms/pharmacy/medicine_sales/${hmsObject?.id}/fetch_medicines/`, null, "fetch_medicines"))
      setBilling({
        subTotal: billing?.sub_total_amount,
        discountPercentage: billing?.discount_percent,
        discountAmount: billing?.discount_amount,
        cgstPercentage: billing?.cgst_percent,
        sgstPercentage: billing?.sgst_percent,
        cgstAmount: billing?.cgst,
        sgstAmount: billing?.sgst,
        paymentMode: billing?.payment_mode,
        grandTotal: billing?.grand_total_amount,
        paymentStatus: billing?.is_paid ? "true" : "false",
        chequeNumber: billing?.cheque_number,
        chequeDate: billing?.cheque_date,
        totalPayableAmount: billing?.grand_total_amount,
        customerName: billing?.customer_name,
      })
    }
    if (apiKey === "patient_details") {
      setPatient(hmsObject)
    }
    if (apiKey === "fetch_medicines") {
      setMedicineList(
        optionsChainedHms.map(item => ({
          ...item,
          quantity: medicineObj?.medicines.find(med => med.id === item.medicine_data.id)?.quantity,
        }))
      )
      setIsModifying(false)
      const patientId = medicineObj?.inpatient || medicineObj?.outpatient
      if (patientId) {
        dispatch(getHms(`/hms/patient/${apiMode}/`, patientId, "patient_details"))
      }
    }
    if (formError && apiKey && apiKey.startsWith("fail_medicine_sale")) {
      const excludedKeys = [
        "payment_mode",
        "is_paid",
        "cheque_number",
        "cheque_date",
      ]
      const errorMessage = Object.entries(formError)
        .filter(([key]) => !excludedKeys.includes(key))
        .map(([key, value]) => `<b>${key}:</b> ${value}</br>`)
        .join('')

      if (errorMessage) toastr.error(errorMessage)
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    values = {
      medicines_post: transformMedicines(medicineList),
      pending_amount_pay: values.pending_amount_pay || false,
      billing_post: {
        customer_name: patient?.patient_data?.user_data?.full_name || billing?.customerName,
        module: "Pharmacy",
        is_paid: apiMode === "inpatients" ? billing?.paymentStatus : true,
        payment_mode: billing?.paymentMode,
        sub_total_amount: billing?.subTotal,
        pending_amount: patient?.pharmacy_pending_amount || null,
        discount_percent: billing?.discountPercentage,
        discount_amount: billing?.discountAmount,
        grand_total_amount: billing?.grandTotal,
        cgst: billing?.cgstAmount || 0,
        sgst: billing?.sgstAmount || 0,
        cgst_percent: billing?.cgstPercentage || 0,
        sgst_percent: billing?.sgstPercentage || 0,
        cheque_number: billing?.paymentMode === "CHEQUE" ? billing?.chequeNumber : null,
        cheque_date: billing?.paymentMode === "CHEQUE" ? billing?.chequeDate : null,
      }
    }

    values.outpatient = apiMode === "outpatients" ? patient?.id : null
    values.inpatient = apiMode === "inpatients" ? patient?.id : null

    const medID = props.match.params.medID
    if (medID) {
      dispatch(updateHms("/hms/pharmacy/medicine_sales/", medID, values, "medicine_sale_update"))
    } else {
      dispatch(postHms("/hms/pharmacy/medicine_sales/", values, "medicine_sale_post"))
    }
  }

  var suTo = null
  const searchMedicines = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, medicine: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/pharmacy/search/inventory/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchPatients = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/${apiMode === "outpatients" ? "outpatients/search_active_patients" : "inpatient-discharges/undischarged_patients"}/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      dispatch(getHms(`/hms/patient/${apiMode}/`, patient.id, "patient_details"))
    }
  }

  const updateMedicineSelect = (newMedicine) => {
    setMedicineList((prevMedicineList) => {
      const isAlreadyAdded = prevMedicineList.some((prevMedicine) => prevMedicine.id === newMedicine.id)

      if (!isAlreadyAdded) {
        toastr.success(`${newMedicine.medicine_data.name}-${newMedicine.batch_number} added`)
        return [...prevMedicineList, { ...newMedicine, quantity: "0", stock: newMedicine.quantity }]
      } else {
        toastr.error(`${newMedicine.medicine_data.name} is already added from batch ${newMedicine.batch_number}`)
      }

      return prevMedicineList
    })
  }

  const removeCartItem = (id) => {
    const filtered = medicineList.filter((item) => item.id !== id)
    setMedicineList(filtered)
  }

  const handleQuantityChange = (index, newQuantity, current_stock) => {
    const truncatedValue = formatPercentage(newQuantity, 6, 0, Math.min(props.match.params.medID ? 1000 : current_stock, 1000))
    const updatedMedicines = [...medicineList]
    updatedMedicines[index].quantity = truncatedValue
    setMedicineList(updatedMedicines)
  }

  const onDiscountPercentageChange = (e) => {
    let discountPercentage = formatPercentage(e.target.value)
    setBilling(prevState => ({ ...prevState, discountPercentage }))
  }

  const onCgstPercentageChange = (e) => {
    const cgstPercentage = formatPercentage(e.target.value)
    setBilling(prevState => ({ ...prevState, cgstPercentage }))
  }

  const onSgstPercentageChange = (e) => {
    const sgstPercentage = formatPercentage(e.target.value)
    setBilling(prevState => ({ ...prevState, sgstPercentage }))
  }

  const calculateTotals = () => {
    const subTotal = floatRoundDown(medicineList?.reduce((acc, med) => acc + med.selling_mrp * med.quantity, 0) || 0)
    const discountAmount = floatRoundDown(subTotal * (billing?.discountPercentage / 100))
    const discountedSubTotal = floatRoundDown(subTotal - discountAmount)
    const cgstAmount = floatRoundDown(discountedSubTotal * (billing?.cgstPercentage / 100))
    const sgstAmount = floatRoundDown(discountedSubTotal * (billing?.sgstPercentage / 100))
    const pharmacyPendingAmount = floatRoundDown((props.match.params.medID ? medicineObj?.pharmacy_pending_amounts?.reduce((acc, item) => acc + (item.billing__grand_total_amount || 0), 0) : patient?.pharmacy_pending_amount) || 0)
    const grandTotal = floatRoundDown(discountedSubTotal + cgstAmount + sgstAmount)
    const totalPayableAmount = floatRoundDown(billing.includePendingAmount ? pharmacyPendingAmount + grandTotal : grandTotal || 0)

    setBilling(prevState => ({ ...prevState, subTotal, discountAmount, grandTotal, cgstAmount, sgstAmount, totalPayableAmount }))
  }

  useEffect(() => {
    if (!isModifying) {
      if (medicineList.length === 0) setBilling(prevState => ({ ...prevState, discountPercentage: 0 }))
      calculateTotals()
    }
  }, [medicineList, billing.discountPercentage, billing.cgstPercentage, billing.sgstPercentage, billing?.includePendingAmount])

  const frameMedicineOptions = (e) => {
    let optionLabel = e?.medicine_data?.name
    if (e?.medicine_data?.dosage) optionLabel += ` | ${e?.medicine_data?.dosage}mg`
    optionLabel += ` | ${e?.batch_number} | Stock-${e?.current_stock}`
    return optionLabel
  }

  const transformMedicines = (originalMedicines) => {
    return originalMedicines.map(medicine => ({
      medicine: medicine.id,
      quantity: medicine.quantity,
      cost: parseFloat(medicine.selling_mrp)
    }))
  }

  const onPaymentStatusChange = (e) => {
    setBilling((prevState) => ({
      ...prevState,
      paymentStatus: e.target.value,
      paymentMode: e.target.value === "false" ? "" : prevState.paymentMode,
    }))
  }

  const onIncludePendingAmountChange = (e) => {
    setBilling((prevState) => ({
      ...prevState,
      includePendingAmount: e.target.checked,
    }))
  }

  const onModeChange = (mode) => {
    setApiMode(mode)
    setPatient(null)
    setBilling((prevState) => ({ ...prevState, paymentStatus: mode !== "inpatients" ? true : "", customerName: null }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Medicine Sale | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={12}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error?.detail}</p>
                ) : (
                  <>
                    {actionStatus === "medicine_sale_post_success" || actionStatus === "medicine_sale_update_success" ? (
                      <div className="p-2">
                        <div className="text-center">
                          <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                          <div className="p-2 mt-2">
                            <h4>Medicine sold out successfully</h4>
                            <div className="mt-4">
                              <div className="d-flex justify-content-center">
                                <Button
                                  color="primary"
                                  className={window.innerWidth <= 425 ? 'btn-sm me-1' : 'me-1'}
                                  onClick={() => window.location.replace("/hms/pharmacy/medicine-sale")}
                                >
                                  Add&nbsp;Another
                                </Button>
                                <Link
                                  to={"/hms/pharmacy/sold-out-medicines"}
                                  className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary me-1' : 'me-1 btn btn-primary'}
                                >
                                  Medicines
                                </Link>
                                <ReportDownload
                                  url={`/hms/pharmacy/medicine_sales/${hmsSingleObject.id}/download_report/`}
                                  label="Print Receipt"
                                />
                                <Link
                                  to={`/hms/pharmacy/sold-out-medicines/${hmsSingleObject.id}`}
                                  className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary' : 'btn btn-primary'}
                                >
                                  Explore
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ["get_medicine_sale"].includes(apiKey) ? (
                      <Loader />
                    ) : (
                      <AvForm onValidSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <div className="ajax-select select2-container mb-3" style={{ flex: "1" }}>
                                  <ButtonGroup className="mb-1 btn-group btn-group-sm">
                                    <Button
                                      color="secondary"
                                      className="btn btn-sm"
                                      outline
                                      onClick={() => onModeChange("direct")}
                                      active={apiMode === "direct"}
                                    >
                                      Direct sale
                                    </Button>
                                    <Button
                                      color="primary"
                                      className="btn btn-sm"
                                      outline
                                      onClick={() => onModeChange("outpatients")}
                                      active={apiMode === "outpatients"}
                                    >
                                      Outpatient
                                    </Button>
                                    <Button
                                      color="success"
                                      className="btn btn-sm"
                                      outline
                                      onClick={() => onModeChange("inpatients")}
                                      active={apiMode === "inpatients"}
                                    >
                                      Inpatient
                                    </Button>
                                  </ButtonGroup>
                                  {apiMode === "direct" ? (
                                    <EAvFieldInput
                                      field="customer_name"
                                      placeholder={PATIENT_TYPE_DIRECT_PLACEHOLDER}
                                      value={billing?.customerName}
                                      onChange={(e) => setBilling((prevState) => ({ ...prevState, customerName: e.target.value }))}
                                      isError={formError?.customer_name}
                                    />
                                  ) : (
                                    <EAsyncSelect
                                      onMenuClose={() => setIsMenuOpen(prev => ({ ...prev, searchPatients: false }))}
                                      menuIsOpen={isMenuOpen.searchPatients}
                                      loadOptions={searchPatients}
                                      onSelect={patientSelect}
                                      selectedOption={[patient]}
                                      placeholder={apiMode === "outpatients" ? OUTPATIENT_SEARCH_PLACEHOLDER : INPATIENT_SEARCH_PLACEHOLDER}
                                      formError={formError?.patient}
                                      getOptionLabel={e => {
                                        const fullName = e?.full_name || e?.patient_data?.user_data?.full_name
                                        const uid = e?.uid || e?.patient_data?.uid
                                        const email = e?.email || e?.patient_data?.user_data?.email
                                        const phoneNumber = e?.phone_number || e?.patient_data?.user_data?.phone_number
                                        return `${fullName} | ${uid} ${email ? `| ${email}` : ""} ${phoneNumber ? `| ${phoneNumber}` : ""}`
                                      }}
                                      getOptionValue={e => e?.id}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3 ajax-select select2-container">
                                  <Label className={formError?.medicine ? "text-danger" : ''}>
                                    Search medicines
                                  </Label>
                                  <EAsyncSelect
                                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, medicine: false }))}
                                    menuIsOpen={isMenuOpen.medicine}
                                    field="medicine"
                                    selectedOption={null}
                                    fetchOptions={searchMedicines}
                                    formError={formError?.medicine}
                                    onSelect={updateMedicineSelect}
                                    placeholder="Type to search medicines..."
                                    getOptionLabel={e => frameMedicineOptions(e)}
                                    getOptionValue={e => e.id}
                                    noOptionsMessage={() => ("No stock existed")}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lx="8">
                                <Card>
                                  <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '605px' }}>
                                    <Table className="table align-middle mb-0 table-nowrap">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Medicine</th>
                                          <th>MRP</th>
                                          <th>Quantity</th>
                                          <th colSpan="2">Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {!isEmpty(medicineList) ? (
                                          medicineList?.map((med, index) => (
                                            <tr key={index}>
                                              <td>
                                                <h5 className="font-size-14 text-truncate">
                                                  {med.medicine_data.name}
                                                </h5>
                                                <p className="small">
                                                  {med.batch_number} | Stock - {med?.current_stock - med.quantity}<br />
                                                  Expire date :{" "}{med.expiry_date}
                                                </p>
                                              </td>
                                              <td>{med.selling_mrp}</td>
                                              <td>
                                                <div style={{ width: "120px" }}>
                                                  <input
                                                    type="number"
                                                    name="quantity"
                                                    className="form-control-sm"
                                                    value={med.quantity}
                                                    onChange={(e) => handleQuantityChange(index, e.target.value, med?.current_stock)}
                                                    max={med?.stock}
                                                    min={1}
                                                    style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                                    onKeyDown={handleKeyDown}
                                                    onWheel={(e) => e.target.blur()}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ width: "120px" }}>{(med.selling_mrp * med.quantity).toFixed(2)}</td>
                                              <td>
                                                <Link
                                                  to="#"
                                                  onClick={() => removeCartItem(med.id)}
                                                  className="action-icon text-danger"
                                                >
                                                  {" "} <i className="mdi mdi-trash-can font-size-18" />
                                                </Link>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td colSpan="5" className="text-center">
                                              Please add medicines
                                            </td>
                                          </tr>
                                        )}
                                        {!isEmpty(medicineList) && (
                                          <tr>
                                            <td colSpan="2"></td>
                                            <th style={{ textAlign: "right" }}>Sub total:</th>
                                            <td>{billing?.subTotal}</td>
                                            <td colSpan={"1"}></td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <h5 className="mt-2">Patient Information</h5>
                            <hr />
                            {patient?.patient_data?.user_data || apiMode === "direct" && billing?.customerName ? (
                              <>
                                {apiMode !== "direct" && (
                                  <PatientInformation
                                    data={patient?.patient_data}
                                    includedFields={[
                                      "patient_name",
                                      "patient_id",
                                      "gender",
                                      "marital_status",
                                      "date_of_birth",
                                      "age",
                                      "mobile",
                                      "email",
                                    ]}
                                  />
                                )}
                                {apiMode === "direct" && (
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Customer name"
                                      value={billing?.customerName}
                                    />
                                  </div>
                                )}
                                <div className="d-flex justify-content-between">
                                  <TableChip
                                    label="Sale type"
                                    value={
                                      apiMode === "outpatients" ? (
                                        <Badge pill color="primary">Outpatient Sale</Badge>
                                      ) : apiMode === "inpatients" ? (
                                        <Badge pill color="success">Inpatient sale</Badge>
                                      ) : (
                                        <Badge pill color="secondary">Direct sale</Badge>
                                      )
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <p className="text-center text-danger">Patient information not existed</p>
                            )}
                            <Row>
                              <Col>
                                <div className="d-flex align-items-center mt-2 mb-3">
                                  <h5 className="me-2 mb-0">Order Summary</h5>
                                  <div title="Direct and Outpatient sales are paid by default" className="text-muted ms-2" style={{ cursor: 'pointer' }}>
                                    <i className="bx bx-info-circle" />
                                  </div>
                                </div>
                                <hr />
                                <div className="table-responsive">
                                  <Table className="table mb-0 table table-sm">
                                    <tbody>
                                      <tr>
                                        <td>Sub total :</td>
                                        <td>{billing?.subTotal}</td>
                                      </tr>
                                      <tr>
                                        <td>Pending amount :</td>
                                        <td>
                                          {(props.match.params.medID ? medicineObj?.pharmacy_pending_amounts?.reduce((acc, item) => acc + (item.billing__grand_total_amount || 0), 0) : patient?.pharmacy_pending_amount) || 0}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Discount(%) :</td>
                                        <td>
                                          <input
                                            type="number"
                                            name="discount_percent"
                                            className="form-control-sm mb-0 ms-2"
                                            value={billing?.discountPercentage}
                                            disabled={billing?.subTotal == 0}
                                            onChange={onDiscountPercentageChange}
                                            style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                            onKeyDown={handleKeyDown}
                                            onWheel={(e) => e.target.blur()}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Discount amount :</td>
                                        <td>- {billing?.discountAmount}</td>
                                      </tr>
                                      <tr>
                                        <td className="w-50">
                                          <span>CGST(%) :</span>
                                          <input
                                            type="number"
                                            name="cgst_percent"
                                            className="form-control-sm mb-0 ms-2"
                                            value={billing?.cgstPercentage}
                                            disabled={billing?.subTotal == 0}
                                            onChange={onCgstPercentageChange}
                                            style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                            onKeyDown={handleKeyDown}
                                            onWheel={(e) => e.target.blur()}
                                          />
                                        </td>
                                        <td className="w-50">
                                          <span>SGST(%) :</span>
                                          <input
                                            type="number"
                                            name="sgst_percent"
                                            className="form-control-sm mb-0 ms-2"
                                            value={billing?.sgstPercentage}
                                            disabled={billing?.subTotal == 0}
                                            onChange={onSgstPercentageChange}
                                            style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                            onKeyDown={handleKeyDown}
                                            onWheel={(e) => e.target.blur()}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>CGST :</td>
                                        <td>{billing?.cgstAmount}</td>
                                      </tr>
                                      <tr>
                                        <td>SGST :</td>
                                        <td>{billing?.sgstAmount}</td>
                                      </tr>
                                      <tr>
                                        <th>Grand total :</th>
                                        <th>{billing?.grandTotal}</th>
                                      </tr>
                                      {(props.match.params.medID ? medicineObj?.pharmacy_pending_amounts?.reduce((acc, item) => acc + (item.billing__grand_total_amount || 0), 0) : patient?.pharmacy_pending_amount) > 0 && (
                                        <>
                                          <tr>
                                            <td>
                                              <div className="mt-1 mb-n4">
                                                <EAvFieldCheck
                                                  name="pending_amount_pay"
                                                  value={billing.includePendingAmount}
                                                  checked={billing.includePendingAmount}
                                                  options={{ label: "Include pending amount" }}
                                                  onChange={onIncludePendingAmountChange}
                                                  disabled={medicineList.every(medicine => parseInt(medicine.quantity) === 0)}
                                                />
                                              </div>
                                            </td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <th>Total payable amount :</th>
                                            <th>{billing?.totalPayableAmount || 0}</th>
                                          </tr>
                                        </>
                                      )}
                                      <tr>
                                        <td>
                                          <EAvFieldSelect
                                            field="payment_status"
                                            disabled={apiMode !== "inpatients"}
                                            isError={formError?.is_paid}
                                            bsSize="sm"
                                            options={options?.form_schema?.is_paid}
                                            value={billing.paymentStatus}
                                            onChange={onPaymentStatusChange}
                                            choices={<>
                                              <option value={""}>{"-Select option-"}</option>
                                              {options?.form_schema?.is_paid?.choices.map((choice, cidx) =>
                                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                              )}
                                            </>}
                                          />
                                        </td>
                                        <td>
                                          <EAvFieldSelect
                                            mb={false}
                                            field="payment_mode"
                                            disabled={billing.paymentStatus === "false"}
                                            value={billing.paymentMode}
                                            onChange={(e) => setBilling(prevState => ({ ...prevState, paymentMode: e.target.value }))}
                                            isError={formError?.payment_mode}
                                            bsSize="sm"
                                            options={options?.form_schema?.payment_mode}
                                            choices={<>
                                              <option value={""}>{"-Select option-"}</option>
                                              {options?.form_schema?.payment_mode?.choices.map((choice, cidx) =>
                                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                              )}
                                            </>}
                                          />
                                        </td>
                                      </tr>
                                      <ChequeSection
                                        billing={billing}
                                        setBilling={setBilling}
                                        formError={formError}
                                        options={options?.form_schema}
                                        mb={true}
                                      />
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                              <Row className="mt-3">
                                <Col sm="12">
                                  <div className="text-sm-end mt-2 mt-sm-0">
                                    {apiKey === "post_medicine_sale_post" || apiKey === "update_medicine_sale_update" ? (
                                      <Button color="primary" className="float-end" disabled>
                                        {billing?.paymentStatus === "true" ? "Submit" : "Save"} <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                                      </Button>
                                    ) : (
                                      <Button type="submit" color="primary" className="float-end" disabled={medicineList.every(medicine => parseInt(medicine.quantity) === 0)}>
                                        {billing?.paymentStatus === "true" ? "Submit" : "Save"}
                                      </Button>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                          </Col>
                        </Row>
                      </AvForm>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default MedicineSale