import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Badge } from "reactstrap"
import { getHms, resetHmsState } from "store/actions";
import { formatDateTime, genderVerboseFetch, TableBadge, TableChip } from "components/Common/common";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Loader, convertTo12HourFormat } from "pages/HMS/common/common";

const DoctorDetail = (props) => {
  const dispatch = useDispatch();
  const { loading, error, hmsObject, apiKey } = useSelector(state => state.Hms);
  const [doctor, setDoctor] = useState({});

  useEffect(() => {
    if (apiKey === "doctor_details") {
      setDoctor(hmsObject);
    }
  }, [apiKey]);

  useEffect(() => {
    if (props.match.params.doctorID) dispatch(getHms("/hms/staff/doctors/", props.match.params.doctorID, "doctor_details"))
    return () => dispatch(resetHmsState())
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {doctor?.user_data?.full_name && `${doctor.user_data.full_name} |`} Yanthura
          </title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "get_doctor_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Doctor Information</h5>
                      <Link title="Modify" className="text-muted" to={`/hms/staff/doctors/add/${doctor.id}`}>
                        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                      </Link>
                    </div>
                    <hr />
                    {doctor?.user_data ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Doctor name"
                            value={`${doctor?.user_data?.full_name}`}
                          />
                          <TableChip
                            label="Doctor ID"
                            value={doctor?.user_data?.username}
                            infoTitle="This can be used for login purpose"
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Gender"
                            value={genderVerboseFetch(doctor?.user_data?.gender)}
                          />
                          <TableChip
                            label="Date of birth"
                            value={formatDateTime(doctor?.user_data?.dob, "MMM D, YYYY", "NA")}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Mobile"
                            value={doctor?.user_data?.phone_number}
                          />
                          <TableChip
                            label="Email"
                            value={doctor?.user_data?.email}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Address"
                            value={doctor?.user_data?.address}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Doctor information not existed</p>
                    )}
                  </Col>
                  <Col lg={1}></Col>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Professional Information</h5>
                    </div>
                    <hr />
                    {!isEmpty(doctor) ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableBadge
                            label="Qualifications"
                            value={doctor?.qualifications}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Registration number"
                            value={doctor?.registration_number}
                          />
                          <TableChip
                            label="Years Of experience"
                            value={doctor?.experience}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Specializations"
                            value={doctor?.specialization}
                          />
                          <TableChip
                            label="Fee"
                            value={doctor?.fee}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Schedules"
                            value={
                              <span>
                                {!isEmpty(doctor?.time_slots) ? doctor?.time_slots?.map((item, idx) => (
                                  <Badge key={idx} color="secondary" pill className="me-1">
                                    {convertTo12HourFormat(item)}
                                  </Badge>
                                )) : (
                                  <p>No timeslots available</p>
                                )}
                              </span>
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Known languages"
                            value={doctor.languages_known}
                          />
                          <TableChip
                            label="Extra specializations"
                            value={doctor.extra_specializations}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Professional information not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  );
};

export default DoctorDetail;
