import React, { useEffect, useState } from "react"
import { Badge, Table } from "reactstrap"
import { calculateUnitDifference, floatRoundDown, formatPercentage, handleKeyDown } from "pages/HMS/common/common"
import { formatDateTime } from "components/Common/common"
import { EAsyncSelect, EAvFieldDiscountPercentage, EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import { useSelector } from "react-redux"
import { get } from "helpers/api_helper"
import toastr from "toastr"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { BedCRUDModal, TreatmentCRUDModal } from "./modals"
import { deleteHms } from "store/actions"
import { isEmpty } from "lodash"
import { HmsSimpleDeleteModal } from "pages/HMS/common/hms-crud-modals"

export const TreatmentTable = ({ treatments, inpatientID, billing = {} }) => {
  const { apiKey, loading } = useSelector((state) => state.Hms)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [rudFlag, setRudFlag] = useState(null)
  const [treatmentObject, setTreatmentObject] = useState({})

  useEffect(() => {
    if (apiKey === "discharge_treatment_deleted") {
      setDeleteModal(false)
    }
  }, [apiKey])

  const handleAdd = () => {
    setTreatmentObject({ inpatient: inpatientID })
    setRudFlag(1)
    toggleModal(false)
  }

  const handleEdit = treatment_object => {
    setTreatmentObject(treatment_object)
    setRudFlag(0)
    toggleModal(false)
  }

  const handleDelete = treatment_object => {
    setTreatmentObject(treatment_object)
    setDeleteModal(true)
  }

  const toggleModal = (def = modal) => {
    setModal(!def)
  }

  return (
    <>
      {!isEmpty(treatments) ? (
        <Table className="ip-discharge-billing-table-styles table-sm">
          <thead className="thead-light text-capitalize">
            <tr>
              <td colSpan="4" className="text-end">
                <Link to="#" className="me-2 btn btn-primary btn-sm" onClick={handleAdd}>+ Add Treatment</Link>
              </td>
            </tr>
            <tr>
              <th>Treatment</th>
              <th>Performed Doctor</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {treatments?.map((treatment, index) => (
              <tr key={index}>
                <td>{treatment?.procedure_data?.find(item => item?.id === treatment?.procedure)?.name || ""}</td>
                <td>{treatment?.performed_doctor_data?.user_data?.full_name}</td>
                <td>{formatDateTime(treatment?.performed_date, "MMM D, YYYY h:mm A")}</td>
                <td>
                  <span className="float-start">{treatment?.cost}</span>
                  <span className="float-end">
                    <Link to="#" className="text-success me-3" onClick={() => handleEdit(treatment)} title="Edit">
                      <i className="mdi mdi-pencil font-size-18" />
                    </Link>
                    {apiKey === "delete_discharge_treatment_deleted" ? (
                      <Link to="#" className="text-danger" title="Delete">
                        <i className="mdi mdi-close-thick font-size-18" />
                      </Link>
                    ) : (
                      <Link to="#" className="text-danger" onClick={() => !loading && handleDelete(treatment)} title="Delete">
                        <i className="mdi mdi-close-thick font-size-18" />
                      </Link>
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3" className="text-end">
                <p className="m-0 cursor-pointer">Total amount:</p>
                <p className="m-0 cursor-pointer"><strong>Due amount:</strong></p>
              </td>
              <td colSpan="1">
                <p className="m-0 cursor-pointer">{billing?.treatmentTotalAmount}</p>
                <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.treatmentTotalAmount} - Discount Amount: 0 + Taxes: 0`}>
                  <strong>{billing?.treatmentTotalAmount}</strong>
                </p>
              </td>
            </tr>
          </tfoot>
        </Table>
      ) : (
        <Table className="ip-discharge-billing-table-styles table-sm">
          <thead className="thead-light text-capitalize">
            <tr>
              <td colSpan="4" className="text-end">
                <Link to="#" className="me-2 btn btn-primary btn-sm" onClick={handleAdd}>+ Add Treatment</Link>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="6" className="text-center text-muted">Treatment charges not available</th>
            </tr>
          </tbody>
        </Table>
      )}
      <TreatmentCRUDModal
        modal={modal}
        toggle={toggleModal}
        rudFlag={rudFlag}
        apiStartKey={"discharge_treatment"}
        data={treatmentObject}
      />

      <HmsSimpleDeleteModal
        modal={deleteModal}
        toggle={() => setDeleteModal(false)}
        dispatchOperation={deleteHms("/hms/patient/inpatient-treatments/", treatmentObject?.id, "discharge_treatment_deleted")}
      />
    </>
  )
}

export const PharmacyTable = ({ pharmacyData = [], billing = {} }) => {
  return (
    <Table className="ip-discharge-billing-table-styles table-sm">
      <thead className="thead-light text-capitalize">
        <tr>
          <th>Medicine</th>
          <th>Batch</th>
          <th>Units</th>
          <th>MRP</th>
          <th>Payment</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {pharmacyData?.map((pharmacy, index) => (
          <React.Fragment key={index}>
            <tr>
              <td colSpan="6">
                <Link
                  title="Click here to go to detail page"
                  to={`/hms/pharmacy/sold-out-medicines/${pharmacy?.id}`}
                  className="text-muted"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Purchase Date: {formatDateTime(pharmacy?.created_at, "MMM D, YYYY h:mm A")}
                </Link>
              </td>
            </tr>
            {pharmacy.medicines.map((med, medIndex) => (
              <tr key={medIndex}>
                <td>{med?.name}</td>
                <td>{med?.batch_number}</td>
                <td>{med?.quantity}</td>
                <td>{med?.cost}</td>
                <td>{pharmacy?.billing?.is_paid ? (
                  <Badge color="secondary" pill>Paid</Badge>
                ) : (
                  <Badge color="success" pill>Unpaid</Badge>
                )}
                </td>
                <td>{med?.total_cost}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="5" className="text-end">
            <p className="m-0 cursor-pointer">Total amount:</p>
            <p className="m-0 cursor-pointer">Paid amount:</p>
            <p className="m-0 cursor-pointer"><strong>Due amount:</strong></p>
          </td>
          <td colSpan="1">
            <p className="m-0 cursor-pointer">{billing?.pharmacyTotalAmount}</p>
            <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.pharmacyPaidSubtotalAmount} - Discount Amount: ${billing?.pharmacyPaidDiscountTotalAmount} + Taxes: ${billing?.pharmacyPaidTaxAmount}`}>
              {billing?.pharmacyPaidAmount}
            </p>
            <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.pharmacyUnpaidSubtotalAmount} - Discount Amount: ${billing?.pharmacyUnpaidDiscountTotalAmount} + Taxes: ${billing?.pharmacyUnpaidTaxAmount}`}>
              <strong>{billing?.pharmacyDueAmount}</strong>
            </p>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export const BedTable = ({ beds, inpatientID, billing }) => {
  const { loading, apiKey } = useSelector((state) => state.Hms)
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rudFlag, setRudFlag] = useState(null);
  const [bedObject, setBedObject] = useState({});
  const [bedData, setBedData] = useState([]);

  useEffect(() => {
    if (apiKey === "discharge_bed_deleted") {
      setDeleteModal(false)
    }
  }, [apiKey]);

  useEffect(() => {
    if (beds) {
      const updatedBedData = beds?.map((bed) => ({
        ...bed,
        fromDate: bed.from_date,
        toDate: bed.to_date,
        unit: bed?.unit || calculateUnitDifference(bed.from_date, bed.to_date),
        price: bed.bed_detail_data.price,
        cost: bed?.cost ?? bed.bed_detail_data.price,
      }));
      setBedData(updatedBedData || []);
    }
  }, [beds]);

  const toggleModal = () => setModal(!modal);

  const handleAdd = () => {
    setBedObject({ inpatient: inpatientID });
    setRudFlag(1);
    toggleModal();
  };

  const handleEdit = (bed) => {
    setBedObject(bed);
    setRudFlag(0);
    toggleModal();
  };

  const handleDelete = (bed) => {
    setBedObject(bed);
    setDeleteModal(true);
  };

  return (
    <>
      <Table className="ip-discharge-billing-table-styles table-sm">
        <thead className="thead-light text-capitalize">
          <tr>
            <td colSpan="7" className="text-end">
              <Link
                to="#"
                className="me-2 btn btn-primary btn-sm"
                onClick={handleAdd}
              >
                + Add Bed
              </Link>
            </td>
          </tr>
          <tr>
            <th>Category</th>
            <th>Ward</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Units</th>
            <th>Cost</th>
            <th>Amount</th>
          </tr>
        </thead>
        {bedData.length > 0 ? (
          <tbody>
            {bedData.map((bed) => (
              <tr key={bed.id}>
                <td>{bed.bed_detail_data.room_category_name}</td>
                <td>{bed.bed_detail_data.ward_name}</td>
                <td>{formatDateTime(bed.fromDate, "MMM D, YYYY h:mm A")}</td>
                <td>{formatDateTime(bed.toDate, "MMM D, YYYY h:mm A")}</td>
                <td>{bed?.unit}</td>
                <td>{bed?.cost}</td>
                <td>
                  <span className="float-start">{bed.unit * bed.cost || 0}</span>
                  <span className="float-end">
                    <Link
                      to="#"
                      className="text-success me-3"
                      onClick={() => handleEdit(bed)}
                      id={`edittooltip-${bed.id}`}
                      title="Edit"
                    >
                      <i className="mdi mdi-pencil font-size-18" />
                    </Link>
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => !loading && handleDelete(bed)}
                      id={`deletetooltip-${bed.id}`}
                      title="Delete"
                    >
                      <i className="mdi mdi-close-thick font-size-18" />
                    </Link>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="7" className="text-center text-muted">
                Bed charges not available
              </td>
            </tr>
          </tbody>
        )}
        {bedData.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan="6" className="text-end">
                <p className="m-0 cursor-pointer">Total amount:</p>
                <p className="m-0 cursor-pointer"><strong>Due amount:</strong></p>
              </td>
              <td colSpan="1">
                <p className="m-0 cursor-pointer">{billing?.bedTotalAmount}</p>
                <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.bedTotalAmount} - Discount Amount: 0 + Taxes: 0`}>
                  <strong>{billing?.bedTotalAmount}</strong>
                </p>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>

      <BedCRUDModal
        modal={modal}
        toggle={toggleModal}
        rudFlag={rudFlag}
        apiStartKey="discharge_bed"
        data={bedObject}
      />

      <HmsSimpleDeleteModal
        modal={deleteModal}
        toggle={() => setDeleteModal(false)}
        dispatchOperation={deleteHms("/hms/patient/inpatient-bedhistory/", bedObject.id, "discharge_bed_deleted")}
      />
    </>
  );
};


export const DiagnosisTable = ({ bookTests = [], billing = {} }) => {
  return (
    <Table className="ip-discharge-billing-table-styles table-sm">
      <thead className="thead-light text-capitalize">
        <tr>
          <th>Test</th>
          <th>Date</th>
          <th>Status</th>
          <th>Payment</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {bookTests?.map((bookTest, index) => (
          <React.Fragment key={index}>
            {bookTest.tests_data.map((test, index) => (
              <tr
                key={index}
                className={bookTest?.status !== "Completed" ? "table-danger" : ""}
                title={bookTest?.status !== "Completed" ? "Complete this test before paying the paying bills" : ""}
              >
                <td>{test?.name}</td>
                <td>
                  <Link
                    title="Click here to go to detail page"
                    to={`/hms/diagnosis/test/${bookTest?.id}/detail`}
                    className="text-muted"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatDateTime(bookTest?.created_at, "MMM D, YYYY h:mm A")}
                  </Link>
                </td>
                <td>{bookTest?.status}</td>
                <td>{bookTest?.billing_data?.is_paid ? (
                  <Badge color="secondary" pill>Paid</Badge>
                ) : (
                  <Badge color="success" pill>Unpaid</Badge>
                )}
                </td>
                <td>{test?.fee}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-end">
            <p className="m-0 cursor-pointer">Total amount:</p>
            <p className="m-0 cursor-pointer">Paid amount:</p>
            <p className="m-0 cursor-pointer"><strong>Due amount:</strong></p>
          </td>
          <td colSpan="1">
            <p className="m-0 cursor-pointer">{billing?.diagnosisTotalAmount}</p>
            <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.diagnosisPaidSubtotalAmount} - Discount Amount: ${billing?.diagnosisPaidDiscountTotalAmount} + Taxes: ${billing?.diagnosisPaidTaxAmount}`}>
              {billing?.diagnosisPaidAmount}
            </p>
            <p className="m-0 cursor-pointer" title={`Actual Amount: ${billing?.diagnosisUnpaidSubtotalAmount} - Discount Amount: ${billing?.diagnosisUnpaidDiscountTotalAmount} + Taxes: ${billing?.diagnosisUnpaidTaxAmount}`}>
              <strong>{billing?.diagnosisDueAmount}</strong>
            </p>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export const BillingTable = (props) => {
  const { billing, options, discountedPerson, setDiscountedPerson, setBilling } = props
  const { formError } = useSelector((state) => state.Hms)
  const [isMenuOpen, setIsMenuOpen] = useState({ discountedPerson: false })
  const [customError, setCustomError] = useState({ insuranceClaimedAmount: false })

  var suTo
  const searchDiscountUsers = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, discountedPerson: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/inpatients/search_hms_users/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const onCgstPercentageChange = (e) => {
    const cgstPercentage = formatPercentage(e.target.value)
    setBilling(prevState => ({ ...prevState, cgstPercentage }))
  }

  const onSgstPercentageChange = (e) => {
    const sgstPercentage = formatPercentage(e.target.value)
    setBilling(prevState => ({ ...prevState, sgstPercentage }))
  }

  const handleInsuranceChange = (e) => {
    const insuranceClaimedAmount = floatRoundDown(e.target.value)
    if (insuranceClaimedAmount <= billing?.totalDueAmount) {
      setCustomError({ insuranceClaimedAmount: false })
      setBilling(prevState => ({ ...prevState, insuranceClaimedAmount }))
    } else {
      toastr.error("Claimed amount should not exceed grand total")
    }
  }

  const onDiscountPercentChange = (value) => {
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setBilling(prevState => ({ ...prevState, discountPercentage: value }))
    }
  }

  return (
    <Table className="table mb-0 table-sm">
      <colgroup>
        <col style={{ width: '70%' }} />
        <col style={{ width: '30%' }} />
      </colgroup>
      <tbody>
        <tr>
          <td>Total amount :</td>
          <td>{billing?.totalAmount}</td>
        </tr>
        <tr>
          <td>Paid amount :</td>
          <td>
            <p
              className="m-0 cursor-pointer"
              title={`Actual amount: ${billing?.diagnosisPaidSubtotalAmount + billing?.pharmacyPaidSubtotalAmount} - Discount amount: ${billing?.diagnosisPaidDiscountTotalAmount + billing?.pharmacyPaidDiscountTotalAmount} + Taxes: ${billing?.diagnosisPaidTaxAmount + billing?.pharmacyPaidTaxAmount}`}
            >
              {billing?.totalPaidAmount}
            </p>
          </td>
        </tr>
        <tr>
          <td>Due amount :</td>
          <td>
            {billing?.totalDueAmount}
          </td>
        </tr>
        <tr>
          <td>Insurance claimed amount :</td>
          <td>
            <div style={{ width: "145px" }}>
              <input
                type="number"
                name="discount"
                className={`form-control-sm mb-0 ${customError.insuranceClaimedAmount}`}
                style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                onChange={handleInsuranceChange}
                value={billing?.insuranceClaimedAmount}
                onKeyDown={handleKeyDown}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Discount(%) :</td>
          <td>
            <EAvFieldDiscountPercentage
              mb={false}
              style={{ width: "145px" }}
              className="form-control-sm mb-0"
              value={billing?.discountPercentage}
              formError={formError?.discount_percent}
              options={options?.form_schema?.discount_percent}
              onChange={(e) => onDiscountPercentChange(e.target.value)}
              validate={{
                pattern: { value: '^[0-9]+(\.[0-9]{1,2})?$' },
                min: { value: 0 },
                max: { value: 100 }
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Discount amount :</td>
          <td>{billing?.discountAmount}</td>
        </tr>
        {billing?.discountAmount ? (
          <tr>
            <td>Discount type :</td>
            <td>
              <EAvFieldSelect
                mb={false}
                field="discount_type"
                bsSize="sm"
                isError={formError?.discount_type}
                options={{ required: billing?.discountAmount }}
                value={billing?.discountType}
                onChange={(e) => setBilling(prevState => ({ ...prevState, discountType: e.target.value }))}
                style={{ width: "145px" }}
                choices={
                  <>
                    <option value={""}>{"-Select option-"}</option>
                    {options?.form_schema?.discount_type?.choices.map((choice, cidx) =>
                      <option value={choice.value} key={cidx}>{choice.display_name}</option>
                    )}
                  </>
                }
              />
            </td>
          </tr>
        ) : null}
        <tr>
          <td>Discount by :</td>
          <td>
            <div className="ajax-select select2-container" style={{ width: "145px" }}>
              <EAsyncSelect
                onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, discountedPerson: false }))}
                menuIsOpen={isMenuOpen.discountedPerson}
                className="form-control-sm p-0"
                field="discount_by"
                selectedOption={discountedPerson}
                fetchOptions={searchDiscountUsers}
                formError={formError?.discounted_by}
                onSelect={(value) => setDiscountedPerson(value)}
                placeholder="Type to search users..."
                getOptionLabel={e => e.full_name}
                getOptionValue={e => e.id}
                additionalStyles={{
                  control: (provided, state) => ({
                    ...provided,
                    minHeight: "30px",
                  }),
                }}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>CGST(%) :</td>
          <td>
            <div style={{ width: "145px" }}>
              <input
                type="number"
                name="cgst_percent"
                className="form-control-sm mb-0"
                style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                value={billing?.cgstPercentage}
                onChange={onCgstPercentageChange}
                onKeyDown={handleKeyDown}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>CGST :</td>
          <td>{billing?.cgstAmount}</td>
        </tr>
        <tr>
          <td>SGST(%) :</td>
          <td>
            <div style={{ width: "145px" }}>
              <input
                type="number"
                name="sgst_percent"
                className="form-control-sm mb-0"
                style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                value={billing?.sgstPercentage}
                onChange={onSgstPercentageChange}
                onKeyDown={handleKeyDown}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>SGST :</td>
          <td>{billing?.sgstAmount}</td>
        </tr>
        <tr>
          <td>Payment mode :</td>
          <td>
            <EAvFieldSelect
              mb={false}
              field="payment_mode"
              bsSize="sm"
              isError={formError?.payment_mode}
              value={billing.paymentMode}
              onChange={(e) => setBilling(prevState => ({ ...prevState, paymentMode: e.target.value }))}
              options={{ required: true }}
              style={{ width: "145px" }}
              choices={
                <>
                  <option value={""}>{"-Select option-"}</option>
                  {options?.form_schema?.payment_mode?.choices.map((choice, cidx) =>
                    <option value={choice.value} key={cidx}>{choice.display_name}</option>
                  )}
                </>
              }
            />
          </td>
        </tr>
        {billing?.paymentMode === "CHEQUE" && (
          <>
            <tr>
              <td>Cheque number :</td>
              <td>
                <EAvFieldInput
                  field="cheque_number"
                  mb={false}
                  style={{ width: "145px" }}
                  type="text"
                  value={billing?.chequeNumber}
                  onChange={(e) => setBilling((prevState) => ({ ...prevState, chequeNumber: e.target.value }))}
                  isError={formError?.cheque_number}
                  bsSize="sm"
                  options={options?.form_schema?.cheque_number}
                />
              </td>
            </tr>
            <tr>
              <td>Cheque date :</td>
              <td>
                <EAvFieldInput
                  field="cheque_date"
                  mb={false}
                  style={{ width: "145px" }}
                  type="date"
                  value={billing?.chequeDate}
                  onChange={(e) => setBilling((prevState) => ({ ...prevState, chequeDate: e.target.value }))}
                  isError={formError?.cheque_date}
                  bsSize="sm"
                  options={options?.form_schema?.cheque_date}
                />
              </td>
            </tr>
          </>
        )}
        <tr>
          <td>Payable amount :</td>
          <th><h4>{billing?.payableAmount}</h4></th>
        </tr>
      </tbody>
    </Table>
  )
}

export const NoDataIndicationTable = ({ noDataIndication = "Not available", colSpan = "6" }) => (
  <Table className="ip-discharge-billing-table-styles table-sm">
    <tbody>
      <tr>
        <th colSpan={colSpan} className="text-center text-muted">
          {noDataIndication}
        </th>
      </tr>
    </tbody>
  </Table>
)