import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Button } from "reactstrap"
import { useEffect, useState } from "react";
import { get } from "helpers/api_helper";
import { resetHmsState, updateHms } from "store/actions";
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux";
import { EAsyncSelect, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields";
import { SubmitLoaderButton } from "pages/HMS/common/common";

export const ConfigureModal = (props) => {
  const { modals, toggleViewModal, errors, setErrors, inpID, dataObject } = props

  const dispatch = useDispatch()
  const [selectedDutyDoctor, setSelectedDutyDoctor] = useState(null);
  const [selectedNurse, setSelectedNurse] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState({ dutyDoctor: false, nurse: false })

  const { formError, apiKey } = useSelector(state => ({
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (dataObject?.duty_doctor_data && dataObject?.nurse_data) {
      setSelectedDutyDoctor(dataObject?.duty_doctor_data);
      setSelectedNurse(Object.assign(dataObject?.nurse_data, { id: dataObject?.nurse }));
    }
    return () => dispatch(resetHmsState())
  }, [dataObject])

  useEffect(() => {
    if (formError && apiKey === "fail_care_taker_update") {
      setErrors({})
      toastr.error(Object.entries(formError).map(([key, value], idx) =>
        `${key}: ${value}`
      ))
    }

    if (apiKey === "care_taker_update") {
      toastr.success(`Caretakers ${dataObject?.duty_doctor_data || dataObject?.nurse_data ? "modified" : "added"} successfully`)
      toggleViewModal('configureModal')
    }
  }, [apiKey])

  var suTo = null
  const searchDoctors = (value, callback, key) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, [key]: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/doctors/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchNurse = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, nurse: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/nurse/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const handleSubmit = () => {
    if (!selectedDutyDoctor?.id || !selectedNurse?.id) {
      const errorsData = {
        dutyDoctor: !selectedDutyDoctor?.id ? "Duty doctor is required" : "",
        nurse: !selectedNurse?.id ? "Nurse is required" : ""
      };
      setErrors({ ...errors, ...errorsData });
      return
    }
    dispatch(updateHms(`/hms/patient/inpatients/`, inpID, {
      duty_doctor: selectedDutyDoctor.id,
      nurse: selectedNurse.id,
    }, "care_taker_update"))
  };

  const updateDutyDoctorSelect = (value) => {
    setSelectedDutyDoctor(value)
  }

  const updateNurseSelect = (value) => {
    setSelectedNurse(value)
  }

  return (
    <Modal
      isOpen={modals.configureModal}
      toggle={() => toggleViewModal('configureModal')}
      backdrop="static"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggleViewModal('configureModal')}>Assign Caretakers</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3 ajax-select select2-container">
                <EAsyncSelect
                  onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, dutyDoctor: false }))}
                  menuIsOpen={isMenuOpen.dutyDoctor}
                  options={{ required: true, label: "Duty Doctor" }}
                  selectedOption={[selectedDutyDoctor]}
                  fetchOptions={(value, callback) => searchDoctors(value, callback, "dutyDoctor")}
                  formError={errors?.dutyDoctor}
                  onSelect={updateDutyDoctorSelect}
                  placeholder="Type to search doctors..."
                  getOptionLabel={e => `${e.user_data?.full_name}-${e.specialization}`}
                  getOptionValue={e => e.id}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3 ajax-select select2-container">
                <EAsyncSelect
                  onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, nurse: false }))}
                  menuIsOpen={isMenuOpen.nurse}
                  options={{ required: true, label: "Nurse" }}
                  selectedOption={[selectedNurse]}
                  fetchOptions={searchNurse}
                  formError={errors?.nurse}
                  onSelect={updateNurseSelect}
                  placeholder="Type to search nurses..."
                  getOptionLabel={e => `${e.user_data?.full_name}`}
                  getOptionValue={e => e.id}
                />
              </div>
            </Col>
            <RequiredFieldsMessage />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => toggleViewModal('configureModal')}>
            Close
          </Button>
          <SubmitLoaderButton onClick={handleSubmit} loading={apiKey === "update_care_taker_update"}>
            Save
          </SubmitLoaderButton>
        </ModalFooter>
      </div>
    </Modal>
  )
}