import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { CardBody, Card, Row, Col, CardTitle, Button } from "reactstrap"
import { getHms, getHmsOptions, listHms, postHms, resetHmsState, updateHms } from "store/hms/actions"
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { TableChip } from "components/Common/common"
import { get } from "helpers/api_helper"
import { EAsyncSelect, EAvFieldInput, EAvFieldSelect, RequiredFieldsMessage, AsyncSearch } from "pages/HMS/common/errored-avfields"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { GoBack, Loader, PatientInformation, handleError } from "pages/HMS/common/common"
import { ReportDownload } from "components/Common/print-report"

const CreateIP = props => {
  const dispatch = useDispatch()
  const { loading, error, options, hmsObject, hmsList, actionStatus, formError, apiKey, hmsSingleObject } = useSelector(state => state.Hms)
  const [patientSearch, setPatientSearch] = useState(!props.match.params.ipID)
  const [patient, setPatient] = useState({})
  const [formOptions, setFormOptions] = useState(null)
  const [isModifyMode, setIsModifyMode] = useState(false)
  const [modalIssuer, setModalIssuer] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState({ doctor: null, bed: null })
  const [searchOptions, setSearchOptions] = useState({ doctors: null, beds: null })
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/inpatients/?options=formschema", "fetch_ip_options"))
    if (props.match.params.ipID) dispatch(getHms("/hms/patient/inpatients/", props.match.params.ipID, 'get_ip'));
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "get_ip") {
      setIsModifyMode(true)

      setPatient({
        ...hmsObject,
        user_data: hmsObject?.patient_data.user_data,
        address_data: hmsObject?.patient_data?.address_data,
        honorific: hmsObject?.patient_data?.honorific,
        first_name: hmsObject?.patient_data?.first_name,
        last_name: hmsObject?.patient_data?.last_name,
        marital_status: hmsObject?.patient_data?.marital_status,
        age: hmsObject?.patient_data.age,
        emergency_contact_name: hmsObject?.patient_data?.emergency_contact_name,
        emergency_contact_relation: hmsObject?.patient_data?.emergency_contact_relation,
        emergency_contact_number: hmsObject?.patient_data?.emergency_contact_number,
      })

      setSelectedOptions({ doctor: hmsObject?.consulting_doctor_data, bed: hmsObject?.current_bed_data })
      dispatch(resetHmsState())
    }

    if (apiKey === "selected_patient") setPatient(hmsObject)
    if (apiKey === "ip_created") setPatient(hmsSingleObject)
    if (apiKey === "fail_get_ip") setPatientSearch(true)
    if (apiKey === "insurance_issuer_ip_created") dispatch(getHmsOptions("/hms/patient/inpatients/?options=formschema"))
    if (apiKey === "fetch_doctors") setSearchOptions({ ...searchOptions, doctors: hmsList?.results });
    if (apiKey === "fetch_beds") setSearchOptions({ ...searchOptions, beds: hmsList });
    if (formError && apiKey.startsWith("fail_ip")) {
      const excludedKeys = [
        "doctor",
        "diseased_with",
        "current_bed",
        "patient",
        "admission_type",
        "issuer",
        "policy_number",
        "policy_valid_till",
        "payment_mode",
      ]
      handleError(formError, apiKey, "fail_ip", excludedKeys)
    }
  }, [apiKey])

  useEffect(() => {
    if (options.form_schema) setFormOptions(options.form_schema)
  }, [options])

  const toggleIssuer = (def = modalIssuer) => {
    setModalIssuer(!def)
  }

  const handleSubmit = (e, values) => {
    delete values.floor
    delete values.room
    values.consulting_doctor = selectedOptions.doctor ? selectedOptions.doctor.id : null
    values.current_bed = selectedOptions.bed ? selectedOptions.bed.id : null
    values.insurance_data = {
      issuer: values.issuer,
      policy_number: values.policy_number,
      policy_valid_till: values.policy_valid_till
    }
    delete values.issuer
    delete values.policy_number
    delete values.policy_valid_till


    if (isModifyMode) {
      dispatch(updateHms("/hms/patient/inpatients/", patient?.id, values, "ip_modified"))
    } else {
      values.patient = patient?.id
      dispatch(postHms("/hms/patient/inpatients/", values, "ip_created"))
    }
  }

  const searchPatients = (value, callback) => {
    value = value.trim()
    setIsMenuOpen(value.length > 0)
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/patients/search_active_patients/?query=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      dispatch(getHms("/hms/patient/patients/", patient?.id, "selected_patient"))
      setPatientSearch(false)
    }
  }

  var suTo = null
  const searchDoctors = (value, callback) => {
    value = value.trim()
    if (value !== "" && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/doctors/?search=${value}`).then(resp => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchBeds = (value, callback) => {
    value = value.trim()
    if (value !== "" && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/bedconfig/unassigned_beds/?query=${value}`).then(resp => {
          callback(resp)
        })
      }, 200)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IP Registration | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {patientSearch ? (
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    menuIsOpen={isMenuOpen}
                    onMenuClose={() => setIsMenuOpen(false)}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder={"Search patients"}
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: '1px' }}>
                        No patient records found?{" "}
                        <Link to="/hms/patient/register" className="font-weight-bold">
                          Create a new patient
                        </Link>
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{e.full_name}</p>
                          <p className="text-muted mb-0">
                            {e.uid} {e.email ? `| ${e.email}` : ""} | {e.phone_number}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={apiKey === "ip_created" || apiKey === "ip_modified" ? 6 : 12}>
              <Card>
                <CardBody>
                  {error ? (
                    <p className="text-center text-danger">{error.detail}</p>
                  ) : loading && apiKey === "options_fetch_ip_options" || apiKey === "get_get_ip" ? (
                    <Loader />
                  ) : (["ip_created_success", "ip_modified_success"].includes(actionStatus)) ? (
                    <div className="p-2">
                      <div className="text-center">
                        <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                        <div className="p-2 mt-2">
                          <h4>Inpatient {isModifyMode ? "modified" : "created"} successfully</h4>
                          <div className="mt-4">
                            <div className="d-flex justify-content-center">
                              <ReportDownload
                                label="Admission Report"
                                url={`/hms/patient/inpatients/${hmsSingleObject?.id}/download_admission_report/`}
                              />
                              <Button
                                color="primary"
                                onClick={() => window.location.replace("/hms/patient/inpatient/register")}
                                className={window.innerWidth <= 425 ? 'btn-sm me-1' : 'me-1'}
                              >
                                Add&nbsp;Another
                              </Button>
                              <Link
                                to={"/hms/patient/inpatients"}
                                className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary me-1' : 'me-1 btn btn-primary'}
                              >
                                Inpatients
                              </Link>
                              <Button
                                color="primary"
                                onClick={() => window.location.replace(`/hms/patient/inpatient/register/${hmsSingleObject.id}`)}
                                className={window.innerWidth <= 425 ? 'btn-sm' : ''}
                              >
                                Modify
                              </Button>
                              <Link
                                to={`/hms/patient/inpatient/${hmsSingleObject.id}/detail`}
                                className={window.innerWidth <= 425 ? 'btn-sm ms-1 btn btn-primary' : 'ms-1 btn btn-primary'}
                              >
                                Explore
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {formOptions && (
                        <>
                          <CardTitle className="mb-4">
                            {isModifyMode ? "Modify" : "Create an"} Inpatient
                          </CardTitle>
                          <AvForm onValidSubmit={handleSubmit}>
                            <Row>
                              <Col>
                                <Row>
                                  <Col>
                                    <div className="mb-3 ajax-select select2-container">
                                      <EAsyncSelect
                                        cacheOptions
                                        options={{ ...formOptions.doctor, label: "Consulting doctor" }}
                                        selectedOption={[selectedOptions.doctor]}
                                        fetchOptions={searchDoctors}
                                        formError={formError?.doctor}
                                        onSelect={(doctor) => setSelectedOptions({ ...selectedOptions, doctor: doctor })}
                                        placeholder="Type to search doctors..."
                                        getOptionLabel={e => `${e?.user_data?.full_name}-${e?.specialization}`}
                                        getOptionValue={e => e.id}
                                        defaultOptions={searchOptions.doctors}
                                        onFocus={() => dispatch(listHms("/hms/staff/doctors/", null, "fetch_doctors"))}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <EAvFieldInput
                                      field="diseased_with"
                                      type="text"
                                      value={patient?.diseased_with}
                                      isError={formError?.diseased_with}
                                      options={formOptions?.diseased_with}
                                    />
                                  </Col>
                                </Row>
                                <h5 className="mt-2">Bed Details</h5>
                                <hr />
                                <Row>
                                  <Col>
                                    <div className="mb-3 ajax-select select2-container">
                                      <EAsyncSelect
                                        cacheOptions
                                        options={{ ...formOptions.doctor, label: "Bed info" }}
                                        selectedOption={[selectedOptions?.bed]}
                                        fetchOptions={searchBeds}
                                        formError={formError?.current_bed}
                                        onSelect={(bed) => setSelectedOptions({ ...selectedOptions, bed })}
                                        placeholder="Type to search beds..."
                                        getOptionLabel={e => `${e.room_category_name} | ${e.ward_name} | Bed number - ${e.bed_number} | Room - ${e.room_number} | Floor - ${e.floor} | Price - ${e.price}`}
                                        getOptionValue={e => e.id}
                                        defaultOptions={searchOptions.beds}
                                        onFocus={() => dispatch(listHms("/hms/patient/bedconfig/unassigned_beds/", null, "fetch_beds"))}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <h5 className="mt-2">Insurance </h5>
                                  <hr />
                                  <Col>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <EAvFieldSelect
                                            field="admission_type"
                                            value={patient?.admission_type}
                                            isError={formError?.admission_type}
                                            options={{ label: formOptions?.admission_type?.label, required: true }}
                                            choices={
                                              <>
                                                <option value={""}>-------Select an option-------</option>
                                                {formOptions?.admission_type?.choices.map((item, idx) =>
                                                  <option value={item.value} key={idx}>{item.display_name}</option>
                                                )}
                                              </>
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <RequiredFieldsMessage />
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="d-flex justify-content-end">
                                      {apiKey === "post_ip_created" || apiKey === "update_ip_modified" ? (
                                        <>
                                          <GoBack disabled />
                                          <Button color="primary" disabled>
                                            {isModifyMode ? "Modify" : "Create"} Inpatient
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <GoBack historyProp={props.history} />
                                          <Button type="submit" color="primary">
                                            {isModifyMode ? "Modify" : "Create"} Inpatient
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xl="1"></Col>
                              <Col>
                                <h5 className="mt-2">
                                  Patient Information
                                </h5>
                                <hr />
                                {patient?.user_data ? (
                                  <PatientInformation
                                    data={patient}
                                    includedFields={[
                                      "patient_name",
                                      "patient_id",
                                      "gender",
                                      "marital_status",
                                      "date_of_birth",
                                      "age",
                                      "mobile",
                                      "email",
                                    ]}
                                  />
                                ) : (
                                  <p className="text-center text-danger">
                                    Patient information not existed
                                  </p>
                                )}
                                <Row>
                                  <h5 className="mt-2">Insurance Details</h5>
                                  <hr />
                                  {patient?.health_insurance_data ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <TableChip
                                            label="Insurance issuer"
                                            value={patient?.health_insurance_data?.issuer_name}
                                          />
                                        </Col>
                                        <Col>
                                          <TableChip
                                            label="Policy number"
                                            value={patient?.health_insurance_data?.policy_number}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <TableChip
                                            label="Policy valid till"
                                            value={patient?.health_insurance_data?.policy_valid_till}
                                          />
                                        </Col>
                                        <Col>
                                          <TableChip
                                            label="Status"
                                            value={patient?.health_insurance_data?.status}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <p className="text-center text-danger">Insurance details not existed</p>
                                  )}
                                </Row>
                                <Row>
                                  <h5 className="mt-2">Emergency Contact Information</h5>
                                  <hr />
                                  {patient?.emergency_contact_name || patient?.emergency_contact_relation || patient?.emergency_contact_number ? (
                                    <>
                                      <Col>
                                        <TableChip
                                          label="Person name"
                                          value={patient?.emergency_contact_name}
                                        />
                                      </Col>
                                      <Col>
                                        <TableChip
                                          label="Relation"
                                          value={patient?.emergency_contact_relation}
                                        />
                                      </Col>
                                      <Col>
                                        <TableChip
                                          label="Mobile"
                                          value={patient?.emergency_contact_number}
                                        />
                                      </Col>
                                    </>
                                  ) : (
                                    <p className="text-center text-danger">Emergency contact details not existed</p>
                                  )}
                                </Row>
                                <Row>
                                  <h5 className="mt-2">Address</h5>
                                  <hr />
                                  {patient?.address_data ? (
                                    <Col>
                                      <TableChip
                                        label="Line 1"
                                        value={patient?.address_data?.line1}
                                      />
                                      <TableChip
                                        label="Line 2"
                                        value={patient?.address_data?.line2}
                                      />
                                      <Row>
                                        <Col>
                                          <TableChip
                                            label="City"
                                            value={patient?.address_data?.city}
                                          />
                                        </Col>
                                        <Col>
                                          <TableChip
                                            label="State"
                                            value={patient?.address_data?.state}
                                          />
                                        </Col>
                                        <Col>
                                          <TableChip
                                            label="Pincode/Zipcode"
                                            value={patient?.address_data?.pincode}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : (
                                    <p className="text-center text-danger">
                                      Address details not existed
                                    </p>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        <SingleFieldCRUModal
          modalLabel={"Insurance issuer"}
          modal={modalIssuer}
          toggle={toggleIssuer}
          rudFlag={1}          
          apiStartKey={"insurance_issuer_ip"}
          apiEndpoint={"/hms/patient/insurance_issuer/"}
        />
      </div>
    </React.Fragment>
  )
}
export default CreateIP
