import axios from "axios";
import { setTemporarySessionStorage } from "components/Common/common";
import { cleanPayload } from "pages/HMS/common/common";
import toastr from "toastr";

const API_URL = process.env.REACT_APP_DEBUG === "true" ? `${process.env.REACT_APP_DEV_URL}/api` : `${location.protocol}//${location.host}/api`;
const API_URL_PUBLIC = process.env.REACT_APP_DEBUG === "true" ? `${process.env.REACT_APP_DEV_URL}` : `${location.protocol}//${location.host}`;

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const axiosApi = axios.create({
  baseURL: API_URL,
});

const axiosPublicApi = axios.create({
  baseURL: API_URL_PUBLIC,
});

const addInterceptors = (instance) => {
  instance.interceptors.request.use(
    function (request) {
      let userObject = localStorage.getItem("authUser");
      if (userObject) {
        request.headers.common["Authorization"] = `Bearer ${JSON.parse(userObject).access}`;
        request.headers.common["User-Timezone"] = TIMEZONE;
      }
      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
      } else if (error?.response?.status === 500) {
        // window.location.href = '/pages-500';
      } else if (error?.response?.status === 403 && error?.response?.data?.detail && error?.response?.data?.code === "INSUFFICIENT_ROLES") {
        toastr.error(error?.response?.data?.detail);
      } else if (error?.response?.status === 423) {
        window.location.href = `/user-inactive?code=${error.response.data.status}`;
      } else if (error?.response?.status === 404 && error?.response?.data?.code === "NO_CLIENT_HTTP_404") {
        window.location.href = "/notfound";
      } else if (error?.response?.status === 404) {
        // window.location.href = '/pages-404';
      } else if (error?.response?.data?.code === "UNAUTH") {
        setTemporarySessionStorage("message", error?.response?.data?.authentication_error);
        window.location.href = "/unknownhappen";
      }
      return Promise.reject(error);
    }
  );
};

addInterceptors(axiosApi);
addInterceptors(axiosPublicApi);

export async function options(url, config = {}) {
  return await axiosApi.options(url, { ...config }).then((response) => response.data);
}

export async function openPdf(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}

export async function optionsPublic(url, config = {}) {
  return await axiosPublicApi.options(url, { ...config }).then((response) => response.data);
}

export async function getPublic(url, config = {}) {
  return await axiosPublicApi.get(url, { ...config }).then((response) => response.data);
}

export async function postPublic(url, data, config = {}) {
  return axiosPublicApi.post(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function putPublic(url, data, config = {}) {
  return axiosPublicApi.put(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function patchPublic(url, data, config = {}) {
  return axiosPublicApi.patch(url, { ...cleanPayload(data) }, { ...config }).then((response) => response.data);
}

export async function delPublic(url, config = {}) {
  return await axiosPublicApi.delete(url, { ...config }).then((response) => response.data);
}
