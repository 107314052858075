import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

const ProfileSidebarContent = props => {
  const ref = useRef()
  const isMobile = window.innerWidth <= 991
  const [role, setRole] = useState(null)

  useEffect(() => {
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
  }, [])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {isMobile ? (
              <>
                <li>
                  <Link to="/profile">
                    <i className="bx bxs-layout"></i>
                    <span>Preferences</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/profile"><span>Profile</span></Link>
                    </li>
                    <li>
                      <Link to="/profile/display-settings/#font-size"><span>Appearance</span></Link>
                    </li>
                    <li>
                      <Link to="/profile/support"><span>Support</span></Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                <li className="menu-title">Preferences</li>
                <li>
                  <Link to="/profile">
                    <i className="bx icon-letter">PR</i>
                    <span>Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profile/display-settings/#font-size">
                    <i className="bx icon-letter">AP</i>
                    <span>Appearance</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profile/support">
                    <i className="bx icon-letter">SU</i>
                    <span>Support</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default withRouter(ProfileSidebarContent)
